import axios from "axios";
import * as types from "./types";
import { REQUEST_URL } from "../constant/Constant";
import { SuccessToast } from "../components/toaster/toast";

export const getArchivedCompetiotions = (flag, date) => async (dispatch) => {
  dispatch({
    type: types.WINNERS_PODIUM_REQUEST_START,
  });
  try {
    const res = await axios.get(
      `${REQUEST_URL}/api/competitions/winners/?date=${date}&flag=${flag}`
    );
    if (flag === "podium") {
      dispatch({
        type: types.GET_WINNERS_PODIUM_REQUEST_SUCCESS,
        payload: res.data,
      });
      if (res.data.length < 1) {
        SuccessToast("Sorry! There is no record for the requested date.");
      }
    } else if (flag === "top5") {
      dispatch({
        type: types.GET_TOP_5_WINNERS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    if (flag === "podium") {
      dispatch({
        type: types.GET_WINNERS_PODIUM_REQUEST_FAIL,
      });
    } else if (flag === "top5") {
      dispatch({
        type: types.GET_TOP_5_WINNERS_FAIL,
      });
    }
  }
};
