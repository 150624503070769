import React, { useEffect, useState } from "react";
import "./winners.css";
import Footer from "../Landing-page/Footer/footer.jsx";
import Navbar from "../Landing-page/Navbar/navbar.jsx";
import CalenderSection from "./childs/calenderSection";
import CompetitionSection from "./childs/competitionSection";
import { useSelector, useDispatch } from "react-redux";
import { getArchivedCompetiotions } from "../../../actions/winnerPodiumActions";
import { useLocation } from "react-router-dom";

const Winners = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [selectedDate, setSelectedDate] = useState("");
  const { loading, archivedCompetitions } = state.winnersPodium;
  useEffect(() => {
    setDate();
  }, []);

  function setDate(newDate) {
    const date = newDate || new Date();
    setSelectedDate(date.getFullYear() + "-" + (date.getMonth() + 1));
    var currentdate = date.getFullYear() + "-" + (date.getMonth() + 1);
    dispatch(getArchivedCompetiotions("podium", currentdate));
  }

  function getPreviousDate() {
    const currentMonth = new Date(selectedDate).getMonth();
    const currentYear = new Date(selectedDate).getFullYear();
    const previousMonth = currentMonth - 1;
    const previousDate = new Date(currentYear, previousMonth);
    setDate(previousDate);
  }

  function getNextDate() {
    const currentMonth = new Date(selectedDate).getMonth();
    const currentYear = new Date(selectedDate).getFullYear();
    const nextMonth = currentMonth + 1;
    const nextDate = new Date(currentYear, nextMonth);
    setDate(nextDate);
  }

  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#winnerPage") {
      const element = document.getElementById("winnerPage");
      if (element) {
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - 120;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [location]);
  return (
    <>
      <Navbar />
      <section className="winner" id="winnerPage">
        <div>
          <CalenderSection
            loading={loading}
            getNextDate={getNextDate}
            getPreviousDate={getPreviousDate}
            selectedDate={selectedDate}
          />
          <CompetitionSection
            loading={loading}
            archivedCompetitions={archivedCompetitions}
          />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Winners;
