export const AUTHENTICATED_START = "AUTHENTICATED_START";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const USER_LOADED_SUCCESS = "USER_LOADED_SUCCESS";
export const USER_LOADED_FAIL = "USER_LOADED_FAIL";

export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAIL = "UPDATE_USER_PROFILE_FAIL";

export const AUTHENTICATED_SUCCESS = "AUTHENTICATED_SUCCESS";
export const AUTHENTICATED_FAIL = "AUTHENTICATED_FAIL";

export const GOOGLE_AUTH_SUCCESS = "GOOGLE_AUTH_SUCCESS";
export const GOOGLE_AUTH_FAIL = "GOOGLE_AUTH_FAIL";
export const FACEBOOK_AUTH_SUCCESS = "FACEBOOK_AUTH_SUCCESS";
export const FACEBOOK_AUTH_FAIL = "FACEBOOK_AUTH_FAIL";

export const LOGOUT = "LOGOUT";

export const GET_DISCOUNTS = "GET_DISCOUNTS";

export const ACCOUNT_ACTIVATION_CONFIRM_SUCCESS =
  "ACCOUNT_ACTIVATION_CONFIRM_SUCCESS";
export const ACCOUNT_ACTIVATION_CONFIRM_FAIL =
  "ACCOUNT_ACTIVATION_CONFIRM_FAIL";

export const NEWSLETTER_SUBSCRIPTION_SUCCESS =
  "NEWSLETTER_SUBSCRIPTION_SUCCESS";
export const NEWSLETTER_SUBSCRIPTION_FAIL = "NEWSLETTER_SUBSCRIPTION_FAIL";

export const SUCCESS_TO_FALSE_AFTER_COMPLETING_ACTION =
  "SUCCESS_TO_FALSE_AFTER_COMPLETING_ACTION";

// My Acount and Auth action types
export const COMMON_REQUEST_START = "COMMON_REQUEST_START";

export const GET_USER_PAYOUT_TRANSACTION_FAIL =
  "GET_USER_PAYOUT_TRANSACTION_FAIL";
export const GET_USER_PAYOUT_TRANSACTION_SUCCESS =
  "GET_USER_PAYOUT_TRANSACTION_SUCCESS";

export const GET_USER_AFFILIATE_INFO_FAIL = "GET_USER_AFFILIATE_INFO_FAIL";
export const GET_USER_AFFILIATE_INFO_SUCCESS =
  "GET_USER_AFFILIATE_INFO_SUCCESS";

export const WITHDRAW_BALANCE_FAIL = "WITHDRAW_BALANCE_FAIL";
export const WITHDRAW_BALANCE_SUCCESS = "WITHDRAW_BALANCE_SUCCESS";

export const CONNECT_WITH_PAYPAL_FAIL = "CONNECT_WITH_PAYPAL_FAIL";
export const CONNECT_WITH_PAYPAL_SUCCESS = "CONNECT_WITH_PAYPAL_SUCCESS";

export const AUTH_REQUEST_START = "AUTH_REQUEST_START";

export const AUTHENTICATION_FAIL = "AUTHENTICATION_FAIL";
export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";

export const GET_USER_TICKETS_FAIL = "GET_USER_TICKETS_FAIL";
export const GET_USER_TICKETS_SUCCESS = "GET_USER_TICKETS_SUCCESS";
export const GET_USER_TRANSACTIONS_FAIL = "GET_USER_TRANSACTIONS_FAIL";
export const GET_USER_TRANSACTIONS_SUCCESS = "GET_USER_TRANSACTIONS_SUCCESS";

export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_CONFIRM_FAIL = "PASSWORD_RESET_CONFIRM_FAIL";
export const PASSWORD_RESET_CONFIRM_SUCCESS = "PASSWORD_RESET_CONFIRM_SUCCESS";

export const COMPETITION_REQUEST_START = "COMPETITION_REQUEST_START";
export const GET_SINGLE_COMPETITION_FAIL = "GET_SINGLE_COMPETITION_FAIL";
export const GET_SINGLE_COMPETITION_SUCCESS = "GET_SINGLE_COMPETITION_SUCCESS";

export const TICKETS_REQUEST_START = "TICKETS_REQUEST_START";
export const GET_COMPETITION_TICKETS_FAIL = "GET_COMPETITION_TICKETS_FAIL";
export const GET_COMPETITION_TICKETS_SUCCESS =
  "GET_COMPETITION_TICKETS_SUCCESS";

export const REVIEW_REQUEST_START="REVIEW_REQUEST_START";
export const GET_REVIEWS_SUCCESS="GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_FAIL="GET_REVIEWS_FAIL";

export const LUCKY_DIP_FAIL = "LUCKY_DIP_FAIL";
export const LUCKY_DIP_START = "LUCKY_DIP_START";
export const LUCKY_DIP_SUCCESS = "LUCKY_DIP_SUCCESS";

export const GIFTSHOP_REQUEST_START = "GIFTSHOP_REQUEST_START";
export const GET_GIFTSHOP_ITEMS_FAIL = "GET_GIFTSHOP_ITEMS_FAIL";
export const GET_GIFTSHOP_ITEMS_SUCCESS = "GET_GIFTSHOP_ITEMS_SUCCESS";

export const CART_REQUEST_START = "CART_REQUEST_START";
export const ADD_TO_CART_FAIL = "ADD_TO_CART_FAIL";
export const GET_CART_DATA_FAIL = "GET_CART_DATA_FAIL";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const LUCKY_DIP_ADD_TO_CART_SUCCESS = "LUCKY_DIP_ADD_TO_CART_SUCCESS";
export const UPDATE_CART_SUCCESS = "UPDATE_CART_SUCCESS";
export const GET_CART_DATA_SUCCESS = "GET_CART_DATA_SUCCESS";

export const DELETE_CART_ITEM_FAIL = "DELETE_CART_ITEM_FAIL";
export const DELETE_CART_ITEM_SUCCESS = "DELETE_CART_ITEM_SUCCESS";

export const GET_COMPETITIONS_FAIL = "GET_COMPETITIONS_FAIL";
export const GET_COMPETITIONS_SUCCESS = "GET_COMPETITIONS_SUCCESS";

export const GET_HEROPRIZE_COMPETITIONS_SUCCESS =
  "GET_HEROPRIZE_COMPETITIONS_SUCCESS";
export const GET_HEROPRIZE_COMPETITIONS_FAIL =
  "GET_HEROPRIZE_COMPETITIONS_FAIL";

export const TOTAL_GIVE_AWAY_REQUEST_START = "TOTAL_GIVE_AWAY_REQUEST_START";
export const GET_TOTAL_GIVE_AWAY_FAIL = "GET_TOTAL_GIVE_AWAY_FAIL";
export const GET_TOTAL_GIVE_AWAY_SUCCESS = "GET_TOTAL_GIVE_AWAY_SUCCESS";

export const GET_TOP_5_WINNERS_FAIL = "GET_TOP_5_WINNERS_FAIL";
export const GET_TOP_5_WINNERS_SUCCESS = "GET_TOP_5_WINNERS_SUCCESS";
export const WINNERS_PODIUM_REQUEST_START = "WINNERS_PODIUM_REQUEST_START";
export const GET_WINNERS_PODIUM_REQUEST_SUCCESS =
  "GET_WINNERS_PODIUM_REQUEST_SUCCESS";
export const GET_WINNERS_PODIUM_REQUEST_FAIL =
  "GET_WINNERS_PODIUM_REQUEST_FAIL";

export const CONFIRM_WORLDLINE_PAYMENT_SUCCESS =
  "CONFIRM_WORLDLINE_PAYMENT_SUCCESS";
export const WORLDLINE_PAYMENT_REQUEST_START =
  "WORLDLINE_PAYMENT_REQUEST_START";
export const CONFIRM_WORLDLINE_PAYMENT_FAIL = "CONFIRM_WORLDLINE_PAYMENT_FAIL";
