import React, { useEffect, useState } from "react";
import "./transections.css";
import moment from "moment";
import axios from "axios";
import Loader from "react-loader-spinner";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import { REQUEST_URL } from "../../../../constant/Constant";
import TransectionOrderModal from "./TransectionOrdersModal";
import { getUserTransactions } from "../../../../actions/user/profileActions";
import { getTokenIncludedConfig } from "../../../../actions/common";
import { Table } from "react-bootstrap";

const Transections = () => {
  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = React.useState({});
  console.log(data);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const userTransactions = state.user.userTransactions;
  const isLoading = state.user.loading;
  useEffect(() => {
    dispatch(getUserTransactions(currentPage));
  }, [currentPage]);

  const openPopUp = async (orderId) => {
    await axios
      .get(
        REQUEST_URL + `/api/carts/transaction/order/${orderId}/`,
        getTokenIncludedConfig()
      )
      .then((res) => {
        setData(res.data);
        console.log(res.data);
      })
      .catch(function (error) {});
  };

  const pageSize = 20;
  const totalCount = userTransactions?.count || 0;
  const totalPages = Math.ceil(totalCount / pageSize);

  const renderPageNumbers = () => {
    let pageButtons = [];

    pageButtons.push(
      <li key={1} className={`page-item ${currentPage === 1 ? "active" : ""}`}>
        <button className="page-link" onClick={() => setCurrentPage(1)}>
          1
        </button>
      </li>
    );

    if (currentPage > 4) {
      pageButtons.push(
        <li
          key="start-ellipsis"
          className="page-item disabled"
          style={{ color: "white", fontWeight: 700, fontSize: "2rem" }}
        >
          ...
        </li>
      );
    }
    const startPage = Math.max(2, currentPage - 1);
    const endPage = Math.min(totalPages - 1, currentPage + 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <li
          key={i}
          className={`page-item ${i === currentPage ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => setCurrentPage(i)}>
            {i}
          </button>
        </li>
      );
    }
    if (currentPage < totalPages - 2) {
      pageButtons.push(
        <li
          key="end-ellipsis"
          className="page-item disabled"
          style={{ color: "white", fontWeight: 700, fontSize: "2rem" }}
        >
          ...
        </li>
      );
    }

    if (totalPages > 1) {
      pageButtons.push(
        <li
          key={totalPages}
          className={`page-item ${currentPage === totalPages ? "active" : ""}`}
        >
          <button
            className="page-link"
            onClick={() => setCurrentPage(totalPages)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    return pageButtons;
  };

  return (
    <section className="transection-section">
      <div className="transection-table">
        <div className="overflower-video1">
          {/* TRANSECTION-HEAD */}
          <div className="row transection-head1 transection-row1 m-0 setWidth">
            <div className="col">
              <h2>Date</h2>
            </div>
            <div className="col">
              <h2>Order</h2>
            </div>
            <div className="col">
              <h2>Lei</h2>
            </div>
            <div className="col">
              <h2>Method </h2>
            </div>
            <div className="col">
              <h2>Transaction ID</h2>
            </div>
          </div>

          {/* TRANSECTION-DRETAIL-START */}
          {isLoading ? (
            <section className="activetickets">
              {" "}
              <div
                className="items-container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Loader
                  type="ThreeDots"
                  color="#00FFEA"
                  height={80}
                  width={80}
                />
              </div>
            </section>
          ) : (
            userTransactions.results &&
            userTransactions.results.map((obj, i) => {
              return (
                <div
                  key={obj.id}
                  className="row transection-detail1 m-0 setWidth"
                >
                  <div className="col">
                    <p style={{ marginTop: "10px" }}>
                      {moment(obj.date_time).format("ll")}
                    </p>
                  </div>
                  <div className="col">
                    {/* <Button
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                        onClick={() => {
                        setModalShow(true);
                        openPopUp(obj.order);
                      }}
                    ></Button> */}

                    <Button
                      type="button"
                      size="lg"
                      variant="info"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                      onClick={() => {
                        openPopUp(obj.order);
                      }}
                    >
                      Check Order
                    </Button>

                    <div
                      className="modal fade transectionorderdetail"
                      id="exampleModalCenter"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalCenterTitle"
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header header">
                            <h5
                              className="modal-title title"
                              id="exampleModalLongTitle"
                            >
                              Order #: {data ? data.id : ""}
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body body">
                            <div className="table-responsive border-0">
                              <Table
                                striped
                                hover
                                variant="dark"
                                className="mb-0"
                              >
                                <thead className="head">
                                  <tr style={{ textAlign: "center" }}>
                                    <th
                                      style={{
                                        color: "rgb(0, 255, 234)",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      Price
                                    </th>
                                    <th
                                      style={{
                                        color: "rgb(0, 255, 234)",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      id: Competition
                                    </th>
                                    <th
                                      style={{
                                        color: "rgb(0, 255, 234)",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      Shop Product
                                    </th>
                                    <th
                                      style={{
                                        color: "rgb(0, 255, 234)",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      Ticket Number
                                    </th>
                                    <th
                                      style={{
                                        color: "rgb(0, 255, 234)",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      Quantity
                                    </th>
                                  </tr>
                                </thead>
                                <tbody style={{ textAlign: "center" }}>
                                  {data?.order_items &&
                                    data?.order_items.map((item, index) => (
                                      <tr key={index}>
                                        <td>{item.price}</td>
                                        <td>
                                          {item.is_ticket ? item.title : ""}
                                        </td>
                                        <td>
                                          {item.is_ticket ? "" : item.title}
                                        </td>
                                        <td>
                                          {item.is_ticket
                                            ? item.ticket_title
                                            : ""}
                                        </td>
                                        <td>{item.quantity}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </div>
                            <style>
                              {`
                                ::-webkit-scrollbar {
                                  width: 8px;
                                  height: 8px;
                                }
                                ::-webkit-scrollbar-track {
                                  background: black;
                                }
                                ::-webkit-scrollbar-thumb {
                                  background: aqua;
                                }
                              `}
                            </style>
                          </div>
                          <div
                            className="modal-footer"
                            style={{
                              backgroundColor: "black",
                              borderTop: "1px solid rgb(90, 90, 90)",
                            }}
                          >
                            <button
                              type="button"
                              className="btn btn-secondary button"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <TransectionOrderModal
                      show={modalShow}
                      data={data && data}
                      onHide={() => setModalShow(false)}
                    /> */}
                  </div>
                  <div className="col">
                    <p style={{ marginTop: "10px" }}> {obj.amount_paid}</p>
                  </div>
                  <div className="col">
                    <p style={{ marginTop: "10px" }}>{obj.payment_type}</p>
                  </div>
                  <div className="col">
                    <h1 style={{ marginTop: "10px" }}>{obj.transaction_id}</h1>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      {/* TRANSECTION-DRETAIL-END */}

      <div class="d-flex justify-content-start mt-4">
        <nav aria-label="Page navigation">
          <ul class="pagination">
            <li
              class={`page-item ${
                userTransactions?.previous === null ? "disabled" : ""
              }`}
            >
              <button
                disabled={userTransactions?.previous === null}
                onClick={() => setCurrentPage(currentPage - 1)}
                class="page-link"
                aria-label="Previous"
              >
                Prev
              </button>
            </li>

            {renderPageNumbers()}

            <li
              class={`page-item ${
                userTransactions?.next === null ? "disabled" : ""
              }`}
            >
              <button
                disabled={userTransactions?.next === null}
                onClick={() => {
                  console.log("Next button clicked");
                  setCurrentPage((prevPage) => prevPage + 1);
                }}
                class="page-link"
                aria-label="Next"
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
};
export default Transections;
