import React, { useState } from "react";
import "./signup.css";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { signup } from "../../../../actions/user/auth";
import { Link, Redirect } from "react-router-dom";
import { continueWithGoogle } from "../Google/google";
import Navbar from "../../Landing-page/Navbar/navbar.jsx";
import Pic1 from "../../../../assets/053 png google G.png";
import { continueWithFacebook } from "../Facebook/facebook.jsx";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SignUp = ({ signup, isLoading, isAuthenticated, error }) => {
  const { i18n, t } = useTranslation();
  let query = useQuery();
  const [formData, setformData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    re_password: "",
    code: query.get("token"),
  });
  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
    rePassword: false,
  });

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility({
      ...passwordVisibility,
      [field]: !passwordVisibility[field],
    });
  };

  const { first_name, last_name, phone, email, password, code } = formData;

  const onChange = (e) =>
    setformData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    signup(formData);
  };
  // const [textEnter, setTextEnter]=useState(false);
  const [activeField, setActiveField] = useState("");

  const onEnterText = (e) => {
    const { name, value } = e.target;
    if (value) {
      setActiveField(name);
    } else {
      setActiveField("");
    }
  };
  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Navbar />
      <div className="signUp">
        <div className="form-modal">
          <div className="form-toggle">
            <button id="signup-toggle" disabled onclick="toggleLogin()">
              REGISTER
            </button>
          </div>
          <div id="signup-form">
            <form onSubmit={(e) => onSubmit(e)}>
              {/* <div className="btnGroup">
                <button
                  type="button"
                  className="btn box-sd-effect"
                  onClick={continueWithGoogle}
                >
                  <img src={Pic1} alt="" />
                  Google
                </button>
                <button
                  type="button"
                  className="btn box-sd-effect"
                  onClick={continueWithFacebook}
                >
                  <i className="fab fa-facebook-square"></i> Facebook
                </button>
              </div> */}

              {activeField === "first_name" && (
                <span style={{ fontSize: "13px", fontWeight: "600" }}>
                  {t("First Name")}
                </span>
              )}
              <input
                type="text"
                placeholder={t("Enter your first name")}
                name="first_name"
                value={first_name}
                onChange={(e) => {
                  onChange(e);
                  onEnterText(e);
                }}
                onFocus={(e) => setActiveField(e.target.name)}
                onBlur={(e) => !e.target.value && setActiveField("")}
                style={{ borderRadius: "0px" }}
              />

              {activeField === "last_name" && (
                <span style={{ fontSize: "13px", fontWeight: "600"}}>
                  {t("Family Name")}
                </span>
              )}
              <input
                type="text"
                placeholder={t("Enter your family name")}
                name="last_name"
                value={last_name}
                onChange={(e) => {
                  onChange(e);
                  onEnterText(e);
                }}
                onFocus={(e) => setActiveField(e.target.name)}
                onBlur={(e) => !e.target.value && setActiveField("")}
                style={{ borderRadius: "0px" }}
              />
              {/* 
        {activeField === "phone" && <span>{t("Number")}</span>}
              <input
                type="text"
                placeholder={t("Phone Number")}
                name="phone"
                value={phone}
                  onChange={(e) => {
                  onChange(e);
                  onEnterText(e);
              }}
              onFocus={(e) =>  setActiveField(e.target.name)}
              onBlur={(e) => !e.target.value && setActiveField("")}
              style={{borderRadius:"5px"}}
              /> */}
              {activeField === "email" && (
                <span style={{ fontSize: "13px", fontWeight: "600" }}>
                  {t("Email address")}
                </span>
              )}
              <input
                type="email"
                placeholder={t("Enter your email")}
                name="email"
                value={email}
                onChange={(e) => {
                  onChange(e);
                  onEnterText(e);
                }}
                onFocus={(e) => setActiveField(e.target.name)}
                onBlur={(e) => !e.target.value && setActiveField("")}
                style={{ borderRadius: "0px" }}
              />
              <div className="password-input">
                {activeField === "password" && (
                  <span style={{ fontSize: "13px", fontWeight: "600" }}>
                    {t("Password")}
                  </span>
                )}
                <input
                  type={passwordVisibility.password ? "text" : "password"}
                  placeholder={t("Enter your password")}
                  name="password"
                  value={password}
                  minLength="6"
                  onChange={(e) => {
                    onChange(e);
                    onEnterText(e);
                  }}
                  onFocus={(e) => setActiveField(e.target.name)}
                  onBlur={(e) => !e.target.value && setActiveField("")}
                  style={{ borderRadius: "0px" }}
                />
                {passwordVisibility.password ? (
                  <i
                    style={{ fontSize: "20px", color: "#676767" }}
                    className="fa fa-eye"
                    onClick={() => togglePasswordVisibility("password")}
                  ></i>
                ) : (
                  <i
                    style={{ fontSize: "20px", color: "#676767" }}
                    className="fa fa-eye-slash"
                    onClick={() => togglePasswordVisibility("password")}
                  ></i>
                )}
              </div>
              <p>
                {t("Clicking CREATE ACCOUNT means that you are agree to our")}
                <a href="javascript:void(0)">
                  {" "}
                  <strong style={{whiteSpace:"nowrap"}} >
                    <NavLink to="/terms">{t("TermsOfServices")}</NavLink>
                  </strong>
                </a>
                .
              </p>

              {activeField === "code" && (
                <span style={{ fontSize: "13px", fontWeight: "600" }}>
                  {t("Registration RC code")}
                </span>
              )}
              <input
                value={code}
                className="rc-code"
                name="code"
                onChange={(e) => {
                  onChange(e);
                  onEnterText(e);
                }}
                onFocus={(e) => setActiveField(e.target.name)}
                onBlur={(e) => !e.target.value && setActiveField("")}
                style={{ borderRadius: "0px" }}
                type="text"
                placeholder={t("Registration RC code")}
              />
              <button type="submit" className="btn login">
                {isLoading ? (
                  <Loader
                    style={{ display: "inline-block" }}
                    type="ThreeDots"
                    color="white"
                    height={5}
                    width={30}
                  />
                ) : (
                  ""
                )}
                {t("CREATE ACCOUNT")}
              </button>
              <p>
                {error != null || error != undefined
                  ? Object.keys(error.data).map((key, index) => (
                      <ul key={index} style={{ paddingLeft: "10px" }}>
                        <li
                          style={{
                            color: "#E0115F",
                            fontSize: "15px",
                            listStyleType: "square",
                          }}
                        >
                          <span>
                            <strong>{key.toUpperCase()}</strong> :{" "}
                          </span>
                          <span>
                            <strong>{error.data[key]}</strong>
                          </span>
                        </li>
                      </ul>
                    ))
                  : null}
              </p>
              <p>
                {t("Already have an account?")}{" "}
                <Link to="/signin">
                  <strong style={{whiteSpace:"nowrap"}}>{t("Sign in.")}</strong>
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.user.loading,
  isAuthenticated: state.user.isAuthenticated,
  error: state.user.error,
});

export default connect(mapStateToProps, { signup })(SignUp);
