import React from "react";
import { withTranslation } from "react-i18next";
import "./faq.css";

import Navbar from "../Navbar/navbar.jsx";

import Footer from "../Footer/footer";

class FAQ extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <Navbar />
        <section className="FAQ" id="faq-title">
          <h1 >FAQ</h1>
          <div class="container my-lg-5 p-0 m-0">
            <div
              class="accordion md-accordion"
              id="accordionEx"
              role="tablist"
              aria-multiselectable="true"
            >
              <div class="card">
                <div class="card-header" role="tab" id="headingOne1">
                  <a
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href="#collapseOne1"
                    aria-expanded="false"
                    aria-controls="collapseOne1"
                  >
                    <h5 class="mb-0">{t("q1")}</h5>
                    <i class="fas fa-angle-down rotate-icon"></i>
                  </a>
                </div>

                <div
                  id="collapseOne1"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="headingOne1"
                  data-parent="#accordionEx"
                >
                  <div class="card-body">
                    <p>{t("answer1")}</p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" role="tab" id="headingTwo2">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href="#collapseTwo2"
                    aria-expanded="false"
                    aria-controls="collapseTwo2"
                  >
                    <h5>{t("q2")}</h5>
                    <i class="fas fa-angle-down rotate-icon"></i>
                  </a>
                </div>

                <div
                  id="collapseTwo2"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="headingTwo2"
                  data-parent="#accordionEx"
                >
                  <div class="card-body">
                    <p>{t("answer2")}</p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" role="tab" id="headingThree3">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href="#collapseThree3"
                    aria-expanded="false"
                    aria-controls="collapseThree3"
                  >
                    <h5 class="mb-0">{t("q10")}</h5>
                    <i class="fas fa-angle-down rotate-icon"></i>
                  </a>
                </div>

                <div
                  id="collapseThree3"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="headingThree3"
                  data-parent="#accordionEx"
                >
                  <div class="card-body">
                    <p>{t("answer10")}</p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" role="tab" id="headingfour4">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href="#collapsefour4"
                    aria-expanded="false"
                    aria-controls="collapsefour4"
                  >
                    <h5 class="mb-0">{t("q3")}</h5>
                    <i class="fas fa-angle-down rotate-icon"></i>
                  </a>
                </div>

                <div
                  id="collapsefour4"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="headingfour4"
                  data-parent="#accordionEx"
                >
                  <div class="card-body">
                    <p>{t("answer3")}</p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" role="tab" id="headingfive5">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href="#collapsefive5"
                    aria-expanded="false"
                    aria-controls="collapsefive5"
                  >
                    <h5 class="mb-0">{t("q4")}</h5>
                    <i class="fas fa-angle-down rotate-icon"></i>
                  </a>
                </div>

                <div
                  id="collapsefive5"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="headingfive5"
                  data-parent="#accordionEx"
                >
                  <div class="card-body">
                    <p>{t("answer4")}</p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" role="tab" id="headingsix6">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href="#collapseThree5"
                    aria-expanded="false"
                    aria-controls="collapseThree5"
                  >
                    <h5 class="mb-0">{t("q5")}</h5>
                    <i class="fas fa-angle-down rotate-icon"></i>
                  </a>
                </div>

                <div
                  id="collapseThree5"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="headingsix6"
                  data-parent="#accordionEx"
                >
                  <div class="card-body">
                    <p>{t("answer5")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default withTranslation()(FAQ);
