import React, { useState, useEffect, useRef } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../../Ticket/tickets.css";
import { NavLink } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

export const Description = ({ competition }) => {
  const sectionRef = useRef(null);
  const seeAllRef = useRef(null);
  const { i18n, t } = useTranslation();
  const [seeAll, setSeeAll] = useState(false);
  const toggleSeeAll = () => setSeeAll(!seeAll);
  const [seeAll1, setSeeAll1] = useState(false);
  const toggleSeeAll1 = () => setSeeAll1(!seeAll1);

  return (
    <div className="tickets">
      <NavLink className="tickets-add" to="/shoppingcart">
        <button className="tickets-addtocart">{t("Go to Cart")}</button>
      </NavLink>
      <div className="discription" ref={sectionRef}>
        <span className="stickyBar" ref={seeAllRef}>
          <div style={{ backgroundColor: "#161413" }} onClick={toggleSeeAll}>
            <h1 className="discriptionSection">
              {seeAll ? <span>-</span> : <span>+</span>}
              <h1>{t("Prize Description")}</h1>
            </h1>
            <hr />
          </div>
        </span>
        <Tabs id="controlled-tab-example" className="alphabets">
          <hr />
          <Tab eventKey="section1">
            <div className={`paragraph ${seeAll ? "show" : "hide"}`}>
              <div>
                <p
                  style={{
                    color: "rgb(210,210,210)",
                  }}
                >
                  {ReactHtmlParser(competition && competition.description)}
                </p>
                <h5 style={{ fontSize: "20px", color: "rgb(210,210,210)" }}>
                  {t("GOOD LUCK")} !
                </h5>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
      <div className="discription">
        <span className="stickyBar">
          <div style={{ backgroundColor: "#161413" }} onClick={toggleSeeAll1}>
            <h1 className="discriptionSection">
              {seeAll1 ? <span>-</span> : <span>+</span>} {t("Rules")}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </h1>
            <hr />{" "}
          </div>{" "}
        </span>
        <Tabs id="controlled-tab-example" className="alphabets">
          <hr />
          <Tab eventKey="section1">
            <div className={`paragraph ${seeAll1 ? "show" : "hide"}`}>
              <div>
                <div className="rules" >
                  <p
                    style={{
                      color: "rgb(210,210,210)",
                      fontSize: "1.57rem",
                      marginBottom: "20px",
                    }}
                  >
                  {competition && competition.title}
                  <br />
                  {competition && competition.total_winners}{" "}
                  {competition.total_winners===1? t("Winner"):t("Winners")} 
                  
                  <br />
                  {t("Maximum")} {competition && competition.total_tickets}{" "}
                  {t("Entries")}
                  <br />
                  {competition && competition.letter_choices} {t("letters")}{" "}
                  {competition && competition.numbers_from}{" "}
                  {t("entries per letter")}
                  </p>
                  <p
                    style={{
                      color: "rgb(210,210,210)",
                      fontSize: "1.57rem",
                    }}
                  >
                            {i18n.language == "ro"
                    ? ReactHtmlParser(competition && competition.rules_romanian)
                    : ReactHtmlParser(competition && competition.rules_english)}
                  </p>

                  <button className="comunity">
                    <a
                      href={competition && competition.live_draw_link}
                      style={{ color: "black" }}
                    >
                      <i className="fas fa-gift"></i>Go to Live Stream
                    </a>
                  </button>
                </div>
                <p style={{ color: "rgb(210,210,210)", marginTop: "10px" }}>
                  <Trans
                    i18nKey="desc_6"
                    components={{
                      1: (
                        <a
                          href="https://www.trustpilot.com/review/paradisecompetitions.ro"
                          target="_blank"
                        />
                      ),
                    }}
                  >
                    Our competitions are totally transparent. Our Winners Podium
                    page with winners names and winners photographs shows proof
                    of it. Our competitions are totally legit. Check our
                    Trustpilot reviews.
                  </Trans>
                  <a
                    href="https://www.trustpilot.com/review/paradisecompetitions.ro"
                    target="_blank"
                  >
                    {" "}
                    here
                  </a>
                </p>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};
