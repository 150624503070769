import React from "react";
import "./privacy-policy.css";
import Navbar from "../Navbar/navbar.jsx";

import Footer from "../Footer/footer";

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <>
        <Navbar />
        <section class="privacy" id="privacy-title" style={{display:"flex",justifyContent:"center", flexDirection:"column"}}>
        <h4 class="mt-lg-4">Privacy Policy & Cookies Policy</h4>
          <div>
            <h4></h4>

            <br />
            <p>
              <div>
                {/* <h4>Privacy Policy</h4> */}

                <p>
                  <strong>
                    <span>Politică de Confidențialitate</span>
                  </strong>
                </p>

                <p>
                  <span>
                    Această Politică de Confidențialitate și Cookie-uri a fost
                    modificată pe 16/02/2024.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>Introducere</span>
                  </strong>
                </p>

                <p>
                  <span>
                    Ne angajăm să protejăm confidențialitatea vizitatorilor și
                    utilizatorilor serviciilor de pe site-ul nostru.
                  </span>
                </p>

                <p>
                  <span>
                    Această politică se aplică atunci când acționăm în calitate
                    de controlor de date în ceea ce privește datele cu caracter
                    personal ale vizitatorilor și utilizatorilor serviciilor
                    noastre; cu alte cuvinte, atunci când determinăm scopurile
                    și mijloacele de prelucrare a acestor date cu caracter
                    personal.
                  </span>
                </p>

                <p>
                  Această politică de confidențialitate și cookie-uri (&quot;
                  <strong>Politică de Confidențialitate</strong>&quot;)
                  detaliază datele utilizate pe www.paradisecompetitions.ro
                  &nbsp;și www.paradisecompetitions.com și aplicațiile Paradise
                  Competitions (&quot;
                  <strong>Site-ul web</strong>&quot;), interfețele web
                  încorporate pe site-urile partenerilor (&ldquo;
                  <strong>Parteneri</strong>&rdquo;), site-urile proprii și
                  paginile noastre de socializare, prin intermediul cărora
                  clienții pot intra în legătură cu noi (&ldquo;
                  <strong>Widget-uri</strong>&rdquo;).
                </p>

                <p>
                  <span>
                    Controlorul de date pentru informațiile dvs. este Glam
                    Infostyle Limited.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>Cum folosim datele dvs. personale</span>
                  </strong>
                </p>

                <p>
                  <span>În această secțiune am detaliat:</span>
                </p>

                <p>
                  <strong>
                    <span>
                      
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      categoriile generale de date cu caracter personal pe care
                      le putem prelucra;
                    </span>
                  </strong>
                </p>

                <p>
                  <strong>
                    <span>
                      
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      scopurile pentru care putem prelucra date cu caracter
                      personal;
                    </span>
                  </strong>
                </p>

                <p>
                  <strong>
                    
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    bazele legale ale prelucrării.
                  </strong>
                </p>

                <p>
                  <span>
                    Putem prelucra date despre utilizarea dvs. a site-ului
                    nostru și a serviciilor noastre, numite &ldquo;
                  </span>
                  <strong>
                    <span>date de utilizare</span>
                  </strong>
                  <span>
                    &ldquo;. Datele de utilizare pot include adresa dvs. IP,
                    locația geografică, tipul și versiunea browser-ului,
                    sistemul de operare, sursa de referință, durata vizitei,
                    numărul de vizualizări de pagini și căile de navigare pe
                    site, precum și informații despre momentul, frecvența și
                    modelul utilizării serviciilor. Sursa datelor de utilizare
                    este Google Analytics. Aceste date de utilizare pot fi
                    procesate în scopul analizării utilizării site-ului și a
                    serviciilor. Baza legală pentru această prelucrare este
                    interesul nostru legitim, și anume monitorizarea și
                    îmbunătățirea site-ului nostru și a serviciilor.
                  </span>
                </p>

                <p>
                  <span>
                    Putem prelucra datele dvs. personale furnizate în cursul
                    utilizării serviciilor noastre, numite &ldquo;
                  </span>
                  <strong>
                    <span>date de serviciu</span>
                  </strong>
                  <span>
                    &ldquo;. Datele de serviciu pot include imagini cu
                    dumneavoastră, nume, adresă, adresă de email, număr de
                    telefon și detalii despre companie. Sursa datelor de
                    serviciu sunteți dumneavoastră. Datele de serviciu pot fi
                    procesate în scopul operării site-ului nostru, furnizării
                    serviciilor noastre, asigurării securității site-ului și a
                    serviciilor noastre, menținerii copiilor de siguranță ale
                    bazelor noastre de date și comunicării cu dumneavoastră.
                    Baza legală pentru această prelucrare este interesul nostru
                    legitim, și anume administrarea corespunzătoare a site-ului
                    nostru și a afacerii.
                  </span>
                </p>

                <p>
                  <span>
                    Putem prelucra informațiile cuprinse în orice cerere pe care
                    ne-o trimiteți cu privire la bunuri și/sau servicii, numite
                    &ldquo;
                  </span>
                  <strong>
                    <span>date de cerere</span>
                  </strong>
                  <span>
                    &ldquo;. Datele de cerere pot fi procesate în scopul
                    oferirii, comercializării și vânzării bunurilor și/sau
                    serviciilor relevante pentru dumneavoastră. Baza legală
                    pentru această prelucrare este consimțământul.
                  </span>
                </p>

                <p>
                  <span>
                    Putem prelucra informații referitoare la relațiile noastre
                    cu clienții, inclusiv informații de contact ale clienților,
                    numite &ldquo;
                  </span>
                  <strong>
                    <span>date de relație cu clienții</span>
                  </strong>
                  <span>
                    &ldquo;. Datele de relație cu clienții pot include numele
                    dumneavoastră, detaliile de contact și informațiile cuprinse
                    în comunicările dintre noi și dumneavoastră. Datele de
                    relație cu clienții pot fi procesate în scopul gestionării
                    relațiilor noastre cu clienții, comunicării cu clienții,
                    menținerii înregistrărilor acestor comunicări și promovării
                    produselor și serviciilor noastre către clienți. Baza legală
                    pentru această prelucrare este interesul nostru legitim, și
                    anume administrarea corespunzătoare a relațiilor noastre cu
                    clienții.
                  </span>
                </p>

                <p>
                  <span>
                    Putem prelucra informații referitoare la tranzacții,
                    inclusiv achiziții de bunuri și servicii pe care le faceți
                    prin intermediul nostru sau al site-ului nostru, numite
                    &ldquo;
                  </span>
                  <strong>
                    <span>date de tranzacție</span>
                  </strong>
                  <span>
                    &ldquo;. Datele de tranzacție pot include detaliile
                    dumneavoastră de contact, detaliile cardului dumneavoastră
                    și detaliile tranzacției. Datele de tranzacție pot fi
                    procesate în scopul furnizării bunurilor și serviciilor
                    achiziționate și menținerii înregistrărilor corespunzătoare
                    ale acestor tranzacții. Baza legală pentru această
                    prelucrare este îndeplinirea unui contract între
                    dumneavoastră și noi și/sau luarea de măsuri, la cererea
                    dumneavoastră, pentru încheierea unui astfel de contract și
                    interesul nostru legitim, și anume administrarea
                    corespunzătoare a site-ului nostru și a afacerii.
                  </span>
                </p>

                <p>
                  <span>
                    Putem prelucra informațiile pe care ni le furnizați în
                    scopul abonării la notificările noastre prin email și/sau
                    buletine informative, numite &ldquo;
                  </span>
                  <strong>
                    <span>date de notificare</span>
                  </strong>
                  <span>
                    &ldquo;. Datele de notificare pot fi procesate în scopul
                    trimiterii dumneavoastră a notificărilor și/sau buletinelor
                    informative relevante. Baza legală pentru această prelucrare
                    este consimțământul.
                  </span>
                </p>

                <p>
                  <span>
                    Putem procesa informațiile conținute în sau referitoare la
                    orice comunicare pe care ne-o trimiteți &ldquo;
                  </span>
                  <strong>
                    <span>datele de corespondență</span>
                  </strong>
                  <span>
                    &ldquo;. Datele de corespondență pot include conținutul
                    comunicării și metadatele asociate acestei comunicări.
                    Site-ul nostru va genera metadatele asociate comunicărilor
                    realizate folosind formularele de contact de pe site. Datele
                    de corespondență pot fi procesate în scopul comunicării cu
                    dumneavoastră și pentru păstrarea înregistrărilor. Baza
                    legală pentru acest proces este interesul nostru legitim, și
                    anume administrarea corespunzătoare a site-ului nostru.
                  </span>
                </p>

                <p>
                  <span>
                    Putem procesa oricare dintre datele dvs. personale
                    identificate în această politică atunci când este necesar
                    pentru stabilirea, exercitarea sau apărarea unor drepturi
                    legale, fie în proceduri judiciare, fie într-un procedeu
                    administrativ sau extrajudiciar. Baza legală pentru acest
                    proces este interesul nostru legitim, și anume protejarea și
                    afirmarea drepturilor noastre legale, drepturile dvs. legale
                    și drepturile legale ale altora.
                  </span>
                </p>

                <p>
                  <span>
                    Putem procesa oricare dintre datele dvs. personale
                    identificate în această politică atunci când este necesar în
                    scopurile obținerii sau menținerii acoperirii de asigurare,
                    gestionării riscurilor sau obținerii de sfaturi
                    profesionale. Baza legală pentru acest proces este interesul
                    nostru legitim, și anume protejarea corespunzătoare a
                    afacerii noastre împotriva riscurilor.
                  </span>
                </p>

                <p>
                  <span>
                    Pe lângă scopurile specifice pentru care putem procesa
                    datele dvs. personale stabilite în această Secțiune, putem
                    procesa, de asemenea, oricare dintre datele dvs. personale
                    atunci când un astfel de proces este necesar pentru
                    respectarea unei obligații legale la care suntem supuși, sau
                    pentru a proteja interesele dvs. vitale sau ale unei alte
                    persoane naturale.
                  </span>
                </p>

                <p>
                  <span>
                    Vă rugăm să nu ne furnizați datele personale ale altor
                    persoane, decât dacă vă îndemnăm să o faceți.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>Furnizarea datelor dvs. personale altora</span>
                  </strong>
                </p>

                <p>
                  <span>
                    Putem dezvălui datele dvs. personale asigurătorilor și/sau
                    consilierilor noștri profesioniști în măsura în care este
                    rezonabil necesar în scopurile obținerii sau menținerii
                    acoperirii de asigurare, gestionării riscurilor, obținerii
                    de consiliere profesională sau pentru stabilirea,
                    exercitarea sau apărarea unor drepturi legale, fie în
                    proceduri judiciare, fie într-un procedeu administrativ sau
                    extrajudiciar.
                  </span>
                </p>

                <p>
                  <span>
                    Putem dezvălui datele dvs. personale furnizorilor sau
                    subcontractanților noștri în măsura în care este rezonabil
                    necesar pentru furnizarea serviciilor noastre.
                  </span>
                </p>

                <p>
                  <span>
                    Tranzacțiile financiare legate de site-ul nostru și
                    serviciile noastre sunt gestionate de furnizorii noștri de
                    servicii de plată. Vom împărtăși datele de tranzacție cu
                    furnizorii noștri de servicii de plată doar în măsura
                    necesară pentru procesarea plăților dvs., rambursarea
                    acestor plăți și gestionarea reclamațiilor și întrebărilor
                    referitoare la aceste plăți și rambursări.
                  </span>
                </p>

                <p>
                  <span>
                    Putem dezvălui datele dvs. de cerere unuia sau mai multor
                    furnizori terți selectați de bunuri și servicii în măsura în
                    care este rezonabil necesar pentru a le permite să vă
                    contacteze, astfel încât să vă poată oferi, comercializa și
                    vinde bunuri și/sau servicii relevante. Fiecare astfel de
                    terț va acționa ca un controlor de date în ceea ce privește
                    datele de cerere pe care i le furnizăm; și, la contactarea
                    dumneavoastră, fiecare astfel de terț vă va furniza o copie
                    a propriei sale politici de confidențialitate, care va
                    guverna utilizarea datelor dvs. personale de către acel
                    terț.
                  </span>
                </p>

                <p>
                  <span>
                    În plus față de dezvăluirile specifice ale datelor personale
                    stabilite în această Secțiune, putem dezvălui datele dvs.
                    personale în cazul în care o astfel de dezvăluire este
                    necesară pentru respectarea unei obligații legale la care
                    suntem supuși sau pentru a proteja interesele dvs. vitale
                    sau ale unei alte persoane naturale. Putem dezvălui, de
                    asemenea, datele dvs. personale în cazul în care o astfel de
                    dezvăluire este necesară pentru stabilirea, exercitarea sau
                    apărarea unor drepturi legale, fie în proceduri judiciare,
                    fie într-un procedeu administrativ sau extrajudiciar.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>
                      Ce se întâmplă cu alte site-uri web legate de site-ul
                      nostru?
                    </span>
                  </strong>
                </p>

                <p>
                  <span>
                    Nu suntem responsabili pentru practicile utilizate de
                    site-urile web legate de sau de pe site-ul nostru, nici
                    pentru informațiile sau conținutul conținut în acestea.
                    Adesea, legăturile către alte site-uri web sunt furnizate
                    exclusiv ca referințe la informații despre subiecte care ar
                    putea fi utile utilizatorilor site-ului nostru.
                  </span>
                </p>

                <p>
                  <span>
                    Vă rugăm să aveți în vedere că atunci când utilizați un link
                    pentru a trece de pe site-ul nostru pe alt site web,
                    Politica noastră de confidențialitate nu mai este în
                    vigoare. Navigarea și interacțiunea dvs. pe orice alt site
                    web, inclusiv site-urile web care au un link pe site-ul
                    nostru, sunt supuse regulilor și politicilor proprii ale
                    acelui site web. Citiți întotdeauna acele reguli și politici
                    înainte de a continua.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>Social Media și Conținut Generat de Utilizatori</span>
                  </strong>
                </p>

                <p>
                  <span>
                    Unele dintre site-urile noastre web permit utilizatorilor
                    să-și trimită propriul conținut. Vă rugăm să aveți în vedere
                    că orice conținut trimis către unul dintre site-urile
                    noastre web poate fi vizualizat de public, și ar trebui să
                    fiți prudenți în ceea ce privește furnizarea anumitor
                    informații personale, de exemplu informații financiare,
                    detalii de adresă sau informații despre angajator, prin
                    aceste site-uri web. Nu suntem responsabili pentru nicio
                    acțiune întreprinsă de alte persoane în cazul în care
                    postați informații personale pe una dintre platformele
                    noastre de socializare, cum ar fi Facebook sau Instagram. Vă
                    rugăm să consultați și politicile lor de confidențialitate
                    și cookie-urile corespunzătoare ale platformelor de
                    socializare pe care le utilizați.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>
                      Tranzacțiile cu Carduri de Debit și Carduri de Credit
                    </span>
                  </strong>
                </p>

                <p>
                  Folosim un procesator de plăți terț, STRIPE, pentru a procesa
                  toate plățile efectuate de dvs. pe site-ul nostru și în
                  aplicație. Noi nu stocăm detalii despre carduri și depindem de
                  STRIPE, pentru acest lucru. Obținem de la STRIPE, doar
                  informații limitate, cum ar fi ultimele patru cifre, țara de
                  emitere și data de expirare. Prelucrarea acestor date de către
                  STRIPE, este acoperită de politica lor de confidențialitate,
                  pe care o puteți consulta pe www.stripe.com.
                </p>

                <p>
                  <strong>
                    <span>Unde stocăm datele dvs. personale</span>
                  </strong>
                </p>

                <p>
                  <span>
                    Datele pe care le colectăm de la dvs. pot fi transferate și
                    stocate într-o destinație în afara țării și a Spațiului
                    Economic European (SEE), inclusiv în SUA și Israel, inclusiv
                    în scopul procesării acestor date de către terțe părți
                    selectate, în vederea facilitării activităților noastre
                    comerciale. Țările din afara SEE pot să nu aibă legi care
                    oferă același nivel de protecție pentru datele dvs.
                    personale ca legile din SEE. În cazul în care acest lucru
                    este valabil, datele personale sunt adecvat protejate prin
                    clauze contractuale standard aprobate de Comisia UE, o
                    certificare Privacy Shield adecvată, reguli corporative
                    obligatorii ale procesatorului unui furnizor sau alt
                    mecanism de adecvare stabilit în conformitate cu legea
                    aplicabilă privind protecția datelor. De exemplu, putem
                    livra direct către dvs. un produs fabricat în Elveția.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>Păstrarea și ștergerea datelor personale</span>
                  </strong>
                </p>

                <p>
                  <span>
                    Această secțiune stabilește politicile și procedurile
                    noastre de păstrare a datelor, concepute pentru a ne asigura
                    că respectăm obligațiile noastre legale în ceea ce privește
                    păstrarea și ștergerea datelor personale.
                  </span>
                </p>

                <p>
                  <span>
                    Datele personale pe care le procesăm în orice scop nu vor fi
                    păstrate mai mult decât este necesar pentru acel scop sau
                    acele scopuri.
                  </span>
                </p>

                <p>
                  <span>
                    Vom păstra datele dvs. personale pentru o perioadă minimă de
                    24 de luni.
                  </span>
                </p>

                <p>
                  <span>
                    Cu toate prevederile celorlalte secțiuni ale acestei
                    politici, putem păstra datele dvs. personale atunci când
                    astfel de păstrare este necesară pentru respectarea unei
                    obligații legale la care suntem supuși sau pentru a proteja
                    interesele vitale ale dvs. sau ale altei persoane naturale.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>Securitatea datelor personale</span>
                  </strong>
                </p>

                <p>
                  <span>
                    Vom lua măsuri tehnice și organizatorice adecvate pentru a
                    asigura securitatea datelor dvs. personale și pentru a
                    preveni pierderea, utilizarea incorectă sau modificarea
                    datelor dvs. personale.
                  </span>
                </p>

                <p>
                  <span>
                    Vom stoca toate datele dvs. personale pe servere securizate,
                    calculatoare personale și dispozitive mobile, și în sisteme
                    sigure de evidență manuală.
                  </span>
                </p>

                <p>
                  <strong>
                    Următoarele date personale vor fi stocate de către noi în
                    formă criptată: numele dvs., informațiile de contact,
                    parolele și datele titularului cardului.
                  </strong>
                </p>

                <p>
                  <strong>
                    Datele referitoare la cererile dvs. și tranzacțiile
                    financiare care sunt trimise de la browserul dvs. web la
                    serverul nostru web, sau de la serverul nostru web la
                    browserul dvs. web, vor fi protejate folosind tehnologie de
                    criptare.
                  </strong>
                </p>

                <p>
                  <strong>
                    Recunoașteți că transmiterea datelor necriptate (sau
                    insuficient criptate) peste internet este inherent nesigură,
                    și nu putem garanta securitatea datelor trimise peste
                    internet.
                  </strong>
                </p>

                <p>
                  <span>
                    Ar trebui să vă asigurați că parola dvs. nu poate fi
                    ghicită, fie de o persoană, fie de un program de calculator.
                    Sunteți responsabil de păstrarea confidențialității parolei
                    pe care o utilizați pentru a accesa site-ul nostru, și nu vă
                    vom solicita parola (cu excepția momentului în care vă
                    autentificați pe site-ul nostru).
                  </span>
                </p>

                <p>
                  <strong>
                    <span>Modificări</span>
                  </strong>
                </p>

                <p>
                  <span>
                    Putem actualiza această politică periodic prin publicarea
                    unei versiuni noi pe site-ul nostru. Ar trebui să verificați
                    această pagină din când în când pentru a vă asigura că
                    sunteți mulțumit de orice schimbare în această politică.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>Drepturile dvs.</span>
                  </strong>
                </p>

                <p>
                  <span>
                    În această secțiune, am rezumat drepturile pe care le aveți
                    în conformitate cu legea privind protecția datelor. Unele
                    dintre drepturi sunt complexe, iar nu toate detaliile au
                    fost incluse în rezumatele noastre. Prin urmare, ar trebui
                    să citiți legile relevante și ghidurile autorităților de
                    reglementare pentru o explicație completă a acestor
                    drepturi. Aveți dreptul la:
                  </span>
                </p>

                <p>
                  <span>
                    
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    acces;
                  </span>
                </p>

                <p>
                  <span>
                    
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    rectificare;
                  </span>
                </p>

                <p>
                  <span>
                    
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    ștergere;
                  </span>
                </p>

                <p>
                  <span>
                    
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    restricționarea prelucrării;
                  </span>
                </p>

                <p>
                  <span>
                    
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    obiecția față de prelucrare;
                  </span>
                </p>

                <p>
                  <span>
                    
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    portabilitatea datelor;
                  </span>
                </p>

                <p>
                  <span>
                    
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a
                    o autoritate de supraveghere;
                  </span>
                </p>

                <p>
                  <span>
                    
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    retragerea consimțământului.
                  </span>
                </p>

                <p>
                  Dacă doriți să exercitați unele dintre aceste drepturi,
                  trebuie să verificăm mai întâi identitatea dvs. înainte de a
                  putea începe modificarea sau recuperarea datelor dvs. Ne
                  propunem să vă furnizăm o prezentare generală a acestor date
                  în termen de o lună. Vă vom informa dacă ne așteptăm să dureze
                  mai mult de o lună.
                </p>

                <p>
                  În ceea ce privește informațiile personale pe care le deținem
                  despre dumneavoastră, vă rugăm să verificați mai întâi
                  conținutul afișat în contul dumneavoastră personal, și în mod
                  specific conținutul afișat în profilul dumneavoastră și în
                  istoricul comenzilor dumneavoastră.{" "}
                  <span>
                    În consecință, ar trebui să citiți paragrafele relevante din
                    termenii și condițiile noastre referitoare la scopul
                    afacerii noastre.
                  </span>
                  Mai mult, în legătură cu informațiile personale pe care le
                  deținem despre dumneavoastră, puteți avea dreptul să ne
                  solicitați o copie a acestor informații personale, cu condiția
                  să nu fie afectate drepturile și libertățile altora.
                </p>

                <p>
                  <span>
                    Aveți dreptul de a corecta orice date personale inexacte
                    despre dumneavoastră și, având în vedere scopurile
                    prelucrării, să completați orice date personale incomplete
                    despre dumneavoastră.
                  </span>
                </p>

                <p>
                  <span>
                    În anumite circumstanțe, aveți dreptul de ștergere a datelor
                    personale fără întârziere nejustificată. Aceste circumstanțe
                    includ: datele personale nu mai sunt necesare în legătură cu
                    scopurile pentru care au fost colectate sau prelucrate în
                    alt mod; retrageți consimțământul pentru prelucrarea bazată
                    pe consimțământ; vă opuneți prelucrării în conformitate cu
                    anumite reguli ale legislației aplicabile privind protecția
                    datelor; prelucrarea are scopuri de marketing direct; și
                    datele personale au fost prelucrate ilegal. Cu toate
                    acestea, există excluderi de la dreptul la ștergere.
                    Excluderile generale includ cazurile în care prelucrarea
                    este necesară: pentru exercitarea dreptului la libertatea de
                    exprimare și informare; pentru respectarea unei obligații
                    legale; sau pentru stabilirea, exercitarea sau apărarea unor
                    pretenții legale.
                  </span>
                </p>

                <p>
                  <span>
                    În anumite circumstanțe, aveți dreptul de a restricționa
                    prelucrarea datelor personale. Aceste circumstanțe sunt:
                    contestați exactitatea datelor personale; prelucrarea este
                    ilegală, dar vă opuneți ștergerii; nu mai avem nevoie de
                    datele personale în scopurile prelucrării noastre, dar aveți
                    nevoie de datele personale pentru stabilirea, exercitarea
                    sau apărarea unor pretenții legale; și v-ați opus
                    prelucrării, până la verificarea acelei obiecții. Dacă
                    prelucrarea a fost restricționată în acest sens, am putea
                    continua să stocăm datele personale. Cu toate acestea, le
                    vom prelucra numai în alte scopuri: cu consimțământul
                    dumneavoastră; pentru stabilirea, exercitarea sau apărarea
                    unor pretenții legale; pentru protejarea drepturilor altei
                    persoane naturale sau juridice; sau din motive de interes
                    public important.
                  </span>
                </p>

                <p>
                  <span>
                    Aveți dreptul să vă opuneți prelucrării datelor personale
                    privind situația dumneavoastră particulară, numai în măsura
                    în care baza legală pentru prelucrare este că prelucrarea
                    este necesară pentru: îndeplinirea unei sarcini realizate în
                    interes public sau în exercitarea oricărei autorități
                    oficiale care ne este conferită; sau pentru scopurile
                    intereselor legitime urmărite de noi sau de o terță parte.
                    Dacă faceți o astfel de obiecție, vom înceta să prelucrăm
                    informațiile personale, cu excepția cazului în care putem
                    demonstra motive legitime și imperioase pentru prelucrare
                    care prevalează asupra intereselor, drepturilor și
                    libertăților dumneavoastră, sau prelucrarea este pentru
                    stabilirea, exercitarea sau apărarea unor pretenții legale.
                  </span>
                </p>

                <p>
                  Aveți dreptul să vă opuneți prelucrării datelor personale în
                  scopuri de marketing direct (inclusiv crearea de profiluri în
                  scopuri de marketing direct). Dacă faceți o astfel de
                  obiecție, vom înceta să prelucrăm datele dumneavoastră
                  personale în acest scop.
                </p>

                <p>
                  <span>
                    Aveți dreptul să vă opuneți prelucrării datelor personale în
                    scopuri de cercetare științifică sau istorică sau în scopuri
                    statistice, pe motive legate de situația dumneavoastră
                    particulară, cu excepția cazului în care prelucrarea este
                    necesară pentru îndeplinirea unei sarcini realizate în
                    interes public.
                  </span>
                </p>

                <p>
                  <span>
                    Aveți dreptul să vă opuneți prelucrării datelor personale în
                    măsura în care baza legală pentru prelucrarea datelor
                    dumneavoastră personale este consimțământul.
                  </span>
                </p>

                <p>
                  <span>
                    Aveți dreptul să vă opuneți prelucrării datelor
                    dumneavoastră personale în măsura în care baza legală pentru
                    prelucrarea datelor dumneavoastră personale este că
                    prelucrarea este necesară pentru îndeplinirea unui contract
                    la care sunteți parte sau pentru a lua măsuri la cererea
                    dumneavoastră înainte de a încheia un contract, iar o astfel
                    de prelucrare este efectuată prin mijloace automate, aveți
                    dreptul de a primi datele personale de la noi într-un format
                    structurat, utilizat în mod curent și ușor de citit de către
                    mașină. Cu toate acestea, acest drept nu se aplică în cazul
                    în care ar afecta în mod negativ drepturile și libertățile
                    altora.
                  </span>
                </p>

                <p>
                  <span>
                    Dacă considerați că prelucrarea informațiilor dumneavoastră
                    personale încalcă legislația privind protecția datelor,
                    aveți dreptul legal de a depune o plângere la o autoritate
                    de supraveghere responsabilă cu protecția datelor. Puteți
                    face acest lucru în statul membru al Uniunii Europene în
                    care vă aflați în mod obișnuit, la locul de muncă sau la
                    locul pretinsei încălcări.
                  </span>
                </p>

                <p>
                  <span>
                    În măsura în care baza legală pentru prelucrarea
                    informațiilor dumneavoastră personale este consimțământul,
                    aveți dreptul de a vă retrage consimțământul în orice
                    moment. Retragerea nu va afecta legalitatea prelucrării
                    anterior retragerii.
                  </span>
                </p>

                <p>
                  <span>
                    Puteți exercita oricare dintre drepturile dumneavoastră în
                    legătură cu datele dumneavoastră personale prin notificare
                    scrisă către noi.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>Websites terțe</span>
                  </strong>
                </p>

                <p>
                  <span>
                    Website-ul nostru include hyperlink-uri către website-uri
                    terțe și detalii despre acestea. Nu avem control asupra
                    politicilor și practicilor de confidențialitate ale terțelor
                    părți și nu suntem responsabili pentru acestea.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>Date personale ale copiilor</span>
                  </strong>
                </p>

                <p>
                  <span>
                    Website-ul nostru și serviciile noastre sunt destinate
                    persoanelor cu vârsta peste 18 ani. Dacă avem motive să
                    credem că deținem date personale ale unei persoane cu vârsta
                    sub aceasta în bazele noastre de date, vom șterge acele date
                    personale.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>Actualizarea informațiilor</span>
                  </strong>
                </p>

                <p>
                  <span>
                    Vă rugăm să ne informați dacă informațiile personale pe care
                    le deținem despre dumneavoastră trebuie corectate sau
                    actualizate.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>Politica noastră privind cookie-urile</span>
                  </strong>
                </p>

                <p>
                  <span>
                    La fel ca alte website-uri și aplicații comerciale,
                    website-ul nostru utilizează tehnologii standard, inclusiv
                    cookie-uri și instrumente similare precum jurnalele
                    serverului web, web beacon-uri, token-uri, etichete pixel,
                    stocarea locală, identificatori de dispozitiv și ID-uri de
                    urmărire (denumite în mod colectiv &quot;Cookie-uri&quot; în
                    această Politică de Confidențialitate) pentru a îmbunătăți
                    experiența dvs. de utilizator, a îmbunătăți site-ul nostru
                    și a oferi oferte personalizate pentru Paradise Competitions
                    și alte site-uri.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>Despre cookie-uri</span>
                  </strong>
                </p>

                <p>
                  <span>
                    Cookie-urile sunt fragmente mici de date trimise
                    computerului dumneavoastră de către un website și stocate pe
                    computerul dumneavoastră. Cookie-urile nu sunt executabile
                    și nu pot fi folosite pentru a instala malware. Ele permit
                    website-urilor să recunoască vizitatorii când aceștia se
                    întorc și să includă informații de bază despre dumneavoastră
                    care sunt verificate și actualizate de fiecare dată când
                    vizitați website-ul.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>Pentru mai multe informații, </span>
                  </strong>
                  <strong>consultați:&nbsp;&nbsp;</strong>
                  <strong>&nbsp;</strong>
                  <a href="https://www.allaboutcookies.org/">
                    https://www.allaboutcookies.org/
                  </a>
                </p>

                <p>
                  <strong>
                    <span>Cookie-urile pe care le putem folosi</span>
                  </strong>
                </p>

                <p>
                  {" "}
                  <strong>
                    <span>Putem folosi cookie-uri în următoarele scopuri:</span>
                  </strong>
                </p>

                <p>
                  <span>
                    {" "}
                    &nbsp; Personalizare &ndash; putem utiliza cookie-uri
                    pentru a stoca informații despre preferințele dumneavoastră
                    și pentru a personaliza website-ul nostru pentru
                    dumneavoastră;
                  </span>
                </p>

                <p>
                  <span>
                    &nbsp; Securitate &ndash; putem utiliza cookie-uri ca
                    element al măsurilor de securitate folosite pentru a proteja
                    conturile de utilizator, inclusiv pentru a preveni
                    utilizarea frauduloasă a datelor de autentificare și pentru
                    a proteja în general website-ul și serviciile noastre;
                  </span>
                </p>

                <p>
                  <span>
                    &nbsp; Publicitate &ndash; putem utiliza cookie-uri pentru
                    a ne ajuta să afișăm reclame relevante pentru dumneavoastră.
                    Cookie-urile folosite în acest scop sunt: Facebook, Twitter,
                    Instagram, Google, Pinterest;
                  </span>
                </p>

                <p>
                  <span>
                    &nbsp; Analiză &ndash; putem utiliza cookie-uri pentru a ne
                    ajuta să analizăm utilizarea și performanța website-ului
                    nostru și a serviciilor noastre.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>Cookie-uri Strict Necesare</span>
                  </strong>
                </p>

                <p>
                  <span>
                    Aceste cookie-uri sunt necesare pentru funcționarea
                    website-ului și nu pot fi dezactivate &ndash; website-ul
                    nostru nu poate funcționa corespunzător fără ele. Puteți
                    seta browserul să blocheze sau să vă alerteze cu privire la
                    aceste cookie-uri, dar unele părți ale site-ului nu vor
                    funcționa în acest caz. Acestea pot fi necesare pentru a vă
                    permite să vă deplasați în jurul website-ului nostru și să
                    utilizați funcțiile noastre, pentru administrarea
                    sistemului, pentru prevenirea activității frauduloase,
                    pentru a vă menține autentificat de la o pagină la alta sau
                    pentru a ne aminti ce ați adăugat în coșul dumneavoastră.
                    Aceste cookie-uri nu stochează nicio informație personal
                    identificabilă.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>Cookie-uri de Performanță</span>
                  </strong>
                </p>

                <p>
                  <span>
                    Aceste cookie-uri ne permit să înțelegem mai bine câți
                    utilizatori vizitează website-ul nostru, cum ajung
                    utilizatorii la noi, navighează sau utilizează website-ul
                    nostru și care părți ale website-ului nostru sunt cele mai
                    populare. De exemplu, ele ne permit să numărăm vizitele și
                    sursele de trafic, astfel încât să putem măsura și
                    îmbunătăți performanța site-ului nostru. Toate informațiile
                    colectate de aceste cookie-uri sunt agregate și, prin
                    urmare, anonime. Dacă nu permiteți aceste cookie-uri, nu vom
                    ști când ați vizitat site-ul nostru și nu vom putea
                    monitoriza performanța acestuia.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>Cookie-uri Funcționale</span>
                  </strong>
                </p>

                <p>
                  <span>
                    <span>
                      Aceste cookie-uri ne permit să furnizăm funcționalități
                      îmbunătățite și personalizare și să simplificăm experiența
                      dvs. de utilizator. De exemplu, acestea pot reține
                      alegerile pe care le faceți, cum ar fi țara din care
                      vizitați website-ul nostru sau limba preferată. Acestea
                      pot fi setate de noi sau de furnizorii terți ale căror
                      servicii le-am adăugat pe paginile noastre. Dacă nu
                      permiteți aceste cookie-uri, unele sau toate aceste
                      servicii ar putea să nu funcționeze corespunzător. Ele nu
                      pot urmări activitatea dvs. de navigare pe alte
                      website-uri sau aplicații în afara Website-ului.
                    </span>
                  </span>
                </p>

                <p>
                  <strong>
                    <span>Cookie-uri de Targetare sau Publicitate</span>
                  </strong>
                </p>

                <p>
                  <span>
                    <span>
                      Utilizarea Cookie-urilor în acest scop ne permite să
                      afișăm anunțuri pe și în afara Website-ului și să colectăm
                      informații despre obiceiurile dvs. de navigare și
                      utilizare a Website-ului pentru a face anunțurile mai
                      relevante și personalizate pentru dvs. și interesele dvs.
                      Putem utiliza tehnologii de retargeting pentru a permite
                      părților terțe să afișeze anunțuri relevante și
                      personalizate pentru dvs. prin rețelele lor. Ele sunt, de
                      asemenea, utilizate pentru a identifica faptul că ați
                      văzut un anumit anunț, limitând numărul de ori în care
                      vedeți un anunț și pentru a măsura eficacitatea unei
                      campanii publicitare. Folosim unele tehnologii ale unor
                      terțe părți în acest scop. Tehnologiile sunt, de asemenea,
                      plasate de site-urile de socializare în scopuri
                      publicitare și de targetare. Aceste tehnologii își
                      amintesc site-urile pe care le vizitați, iar această
                      informație este partajată cu alte părți, cum ar fi
                      advertiserii. Atunci când utilizăm terțe părți pentru
                      scopuri publicitare și de targetare, putem divulga:
                      Informații personal identificabile, cum ar fi adresa de
                      e-mail, ID-ul comenzii, locația. Date generice, agregate
                      sau anonimizate referitoare la vizitele și utilizarea
                      Website-ului nostru; sau Informații sub o formă
                      pseudonimizată, cum ar fi un ID/ cod de cookie al
                      browser-ului sau un hash criptografic al adresei dvs. de
                      e-mail, pentru a ne ajuta să adaptăm și să afișăm
                      anunțurile noastre pentru dvs. pe alte servicii. Acest ID
                      sau cod este comparat cu echivalentul său unic generat în
                      mod similar de partenerii noștri de publicitate pentru a
                      adapta anunțurile pentru dvs.
                    </span>
                  </span>
                </p>

                <p>
                  <span>
                    Companiile terțe pe care le utilizăm în scopuri de targetare
                    și publicitate au propriile lor politici de
                    confidențialitate, pe care ar trebui să le citiți în
                    detaliu.
                  </span>
                </p>

                <p>
                  <strong>
                    <span>
                      Cookie-uri utilizate de furnizorii noștri de servicii
                    </span>
                  </strong>
                </p>

                <p>
                  <span>
                    Furnizorii noștri de servicii utilizează cookie-uri, iar
                    aceste cookie-uri pot fi stocate pe computerul dvs. atunci
                    când vizitați website-ul nostru.
                  </span>
                </p>

                <p>
                  <span>
                    Folosim Google Analytics pentru a analiza utilizarea
                    website-ului nostru. Google Analytics colectează informații
                    despre utilizarea website-ului prin intermediul
                    cookie-urilor. Informațiile colectate referitoare la
                    website-ul nostru sunt utilizate pentru a crea rapoarte
                    despre utilizarea website-ului nostru. Politica de
                    confidențialitate a Google este disponibilă
                    la:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  </span>
                  <a href="https://www.google.com/policies/privacy/">
                    https://www.google.com/policies/privacy/
                  </a>
                </p>

                <p>
                  <strong>
                    <span>Administrarea Cookie-urilor</span>
                  </strong>
                </p>

                <p>
                  <span>
                    Respectăm dreptul dvs. la confidențialitate și suntem
                    bucuroși să vă oferim instrumente pentru a administra
                    cookie-urile pe care le primiți de la serviciile noastre
                    online. Unele cookie-uri sunt esențiale pentru funcționarea
                    Website-ului nostru. Nu puteți renunța la cookie-urile
                    esențiale dacă doriți să utilizați Website-ul nostru. Mai
                    jos, veți găsi o listă cu unele dintre cookie-urile terțe
                    utilizate de noi. Pentru utilizatorii care preferă să nu
                    primească cookie-uri non-esențiale, am furnizat o opțiune de
                    &ldquo;renunțare&rdquo; pe care o puteți selecta.
                  </span>
                </p>

                <p>
                  Majoritatea browserelor vă permit să refuzați acceptarea
                  cookie-urilor și să ștergeți cookie-urile. Metodele de a face
                  acest lucru variază de la browser la browser și de la versiune
                  la versiune.
                </p>

                <p>
                  <span>
                    Blocarea tuturor cookie-urilor va avea un impact negativ
                    asupra utilizabilității multor website-uri.
                  </span>
                </p>

                <p>
                  Dacă blocați cookie-urile, nu veți putea utiliza toate
                  funcționalitățile pe website-ul nostru.
                </p>

                <p>
                  <span>
                    Vă rugăm să consultați meniul &ldquo;Ajutor&rdquo; al
                    browserului sau website-ului pentru mai multe informații.
                    Puteți șterge toate cookie-urile deja existente pe
                    computerul dvs. și puteți seta majoritatea browserelor să le
                    împiedice să fie plasate. Dacă faceți acest lucru, totuși,
                    veți trebui să ajustați manual unele preferințe de fiecare
                    dată când vizitați website-ul nostru sau utilizați
                    Website-ul nostru, iar unele servicii și funcționalități pe
                    care le oferim s-ar putea să nu funcționeze.
                  </span>
                </p>

                <p>
                  <span>
                    Pentru mai multe informații despre tipurile de cookie-uri
                    care sunt exceptate, vă rugăm să consultați:
                  </span>
                </p>

                <p>
                  <a href="https://wikis.ec.europa.eu/display/WEBGUIDE/04.+Cookies">
                    https://wikis.ec.europa.eu/display/WEBGUIDE/04.+Cookies
                  </a>
                </p>

                <p>
                  <span>
                    Dacă utilizați serviciile noastre online fără a bloca
                    cookie-urile, sunteți de acord cu utilizarea cookie-urilor.
                  </span>
                </p>

                <p>
                  <span>Opt Out option cookies</span>
                </p>

                <p>
                  <span>Google: </span>

                  <a href="https://support.google.com/ads/answer/2662922?hl=en">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Opt
                    Out
                  </a>

                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp; _ga _gat _gid __utma, __utmt,
                    __utmb, __utmc, __utmz and
                    __utmv&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </p>

                <p>
                  <span>
                    Mixpanel:
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  </span>

                  <a href="https://help.mixpanel.com/hc/en-us/articles/360000679006-Managing-Personal-Information#optout-users">
                    Opt Out
                  </a>

                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;mp_*&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </p>

                <p>
                  <span>
                    Outbrain:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  </span>

                  <a href="https://help.mixpanel.com/hc/en-us/articles/360000679006-Managing-Personal-Information#optout-users">
                    Opt Out
                  </a>

                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp; Obtp&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </p>

                <p>
                  <span>DoubleClick:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>

                  <a href="https://help.mixpanel.com/hc/en-us/articles/360000679006-Managing-Personal-Information#optout-users">
                    Opt Out
                  </a>

                  <span>&nbsp;&nbsp;&nbsp;DoubleClick&nbsp;&nbsp;&nbsp; </span>
                </p>

                <p>
                  <a href="https://support.google.com/ads/answer/2662922?hl=en">
                    https://support.google.com/ads/answer/2662922?hl=en
                  </a>
                </p>

                <p>
                  <a href="https://help.mixpanel.com/hc/en-us/articles/360000679006-Managing-Personal-Information#optout-users">
                    https://help.mixpanel.com/hc/en-us/articles/360000679006-Managing-Personal-Information#optout-users
                  </a>
                </p>

                <p>
                  <a href="https://help.mixpanel.com/hc/en-us/articles/360000679006-Managing-Personal-Information#optout-users">
                    <span>
                      https://help.mixpanel.com/hc/en-us/articles/360000679006-Managing-Personal-Information#optout-users
                    </span>
                  </a>
                </p>

                <p>
                  <a href="https://help.mixpanel.com/hc/en-us/articles/360000679006-Managing-Personal-Information#optout-users">
                    https://help.mixpanel.com/hc/en-us/articles/360000679006-Managing-Personal-Information#optout-users
                  </a>
                </p>
              </div>
            </p>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}

export default PrivacyPolicy;
