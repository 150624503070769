import React from "react";
import { ProgressBar } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";

export const displayCompetitions = (competitions, offsetTop) => {
  
  return competitions.map((obj, index) => {
    return (
      <div className="items" key={index}>
        <HashLink
          smooth
          className="items-link"
          to={`/competitions/sub-section/${obj.id}#timer`}
          scroll={(el) =>
            window.scrollTo({
              top: el.offsetTop - offsetTop,
              behavior: "smooth",
            })
          }
        >
          <div>
            <div
              style={{
                backgroundImage: `url(${obj.image_url})`,
              }}
            >
              <div
                style={{ fontWeight: "600" }}
                className={
                  obj.tickets_length.sold === obj.tickets_length.total_tickets
                    ? "sold-out"
                    : ""
                }
              >
                {obj.tickets_length.sold === obj.tickets_length.total_tickets
                  ? "Sold Out !"
                  : ""}
              </div>
              <ProgressBar
                now={
                  (obj.tickets_length.sold / obj.tickets_length.total_tickets) *
                  100
                }
              />
            </div>
          </div>
        </HashLink>
        <h3 style={{ fontWeight: "500", fontSize: "1.7rem" }}>{obj.title}</h3>
        {obj.tickets_length.sold === obj.tickets_length.total_tickets ? (
          <></>
        ) : obj.discount_active ? (
          <h2>
            <span
              className={"discount-bar"}
              style={{ fontWeight: "400", fontSize: "1.7rem" }}
            >
              {" "}
              Lei {obj.first_day_price}{" "}
            </span>

            <span style={{ fontWeight: "400", fontSize: "1.7rem" }}>
              {" "}
              Lei {obj.discount_price}
            </span>
            <span
              style={{
                display: "block",
                // marginLeft: "3px",
                fontWeight: "500",
                fontSize: "1.5rem",
              }}
            >
              {obj.discount_text}
            </span>
          </h2>
        ) : (
          <h2>
            <span style={{ fontWeight: "400", fontSize: "1.7rem" }}>
              Lei {obj.first_day_price}
            </span>
          </h2>
        )}
      </div>
    );
  });
};
