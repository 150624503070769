import "./tickets.css";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { Ecard } from "../childs/ecard/ecard";
import { useDispatch, useSelector } from "react-redux";
import { Slider } from "../childs/slidersection/slider";
import { Tickets } from "../childs/tickets/ticketsection";
import { InstantPrices } from "../childs/instantPrices";
import Footer from "../../../Landing-page/Footer/footer.jsx";
import Navbar from "../../../Landing-page/Navbar/navbar.jsx";
import { Description } from "../childs/description/description";
import { loadCompetitionDetail } from "../../../../../actions/competitions/singleCompetitions";

const Ticket = () => {
  const [activeKey, setActiveKey] = useState("A");
  const { id } = useParams();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { details } = state.competitions;

  useEffect(() => {
    dispatch(loadCompetitionDetail(id));
  }, []);

  const [isLoaded, setIsLoaded] = useState(false);

useEffect(() => {
  setIsLoaded(true);
}, []);

  return (
    <div className="main-section">
      <Navbar />
      {Object.keys(details).length !== 0 ? (
        <>
      {   isLoaded ? <> <Slider competition={details} activeKey={activeKey} />
          {details.insta_prizes.length != 0 && <InstantPrices competition={details} />}
          <Tickets competition={details} setActiveKey={setActiveKey} activeKey={activeKey} />
          <Description competition={details} />
          <Ecard competition={details} /></>  :  <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader type="ThreeDots" color="#00FFEA" height={80} width={80} />
        </div>}
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader type="ThreeDots" color="#00FFEA" height={80} width={80} />
        </div>
      )}
     {isLoaded && <Footer />}
    </div>
  );
};

export default Ticket;
