import React, { Fragment, useState, useEffect } from "react";
import "./navbar.css";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "../../../../assets/logo.png";
import { useTranslation } from "react-i18next";

const Navbar = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { i18n, t } = useTranslation();
  const location = useLocation();
  const state = useSelector((state) => state);

  const cart = state.cart.cart;
  const isAuthenticated = state.user.isAuthenticated;
  const [language, setFlag] = useState("romania.png");
  // i18n.logger.options.resorces.map((lan) => console.log(lan));
  const isActive = (match, location) => {
    if (location.pathname.includes("myaccount")) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    switch (i18n.language) {
      case "ro":
        setFlag("romania.png");
        break;
      case "en":
        setFlag("england.png");
        break;
      // case "bu":
      //   setFlag("bulgaria.png");
      //   break;
      // case "de":
      //   setFlag("germany.png");
      //   break;
      // case "es":
      //   setFlag("spain.png");
      //   break;
      // case "fr":
      //   setFlag("france.png");
      //   break;
      // case "it":
      //   setFlag("italia.png");
      //   break;
      // case "mo":
      //   setFlag("moroco.png");
      //   break;
      // case "nl":
      //   setFlag("nietherland.png");
      //   break;
      // case "po":
      //   setFlag("poland.png");
      //   break;
      // case "pt":
      //   setFlag("portugal.png");
      //   break;
      // case "tr":
      //   setFlag("turkey.png");
      //   break;
      default:
        setFlag("romania.png");
    }
  }, [i18n.language]);
  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }
  const guestLinks = () => (
    <Fragment>
      {!props.textColor && !isMobile && (
        <NavLink
          to="/shoppingcart"
          activeClassName="activeNavBtn"
          className="dustbin2"
        >
          <i
            className="fas fa-shopping-cart"
            style={{ color: props.iconColor }}
          ></i>
        </NavLink>
      )}
      <NavLink activeClassName="activeNavBtn" className="NavLink" to="/signin">
        <button className="loginBtn" type="button">
          {t("Client login")}
        </button>
      </NavLink>
      {/* <NavLink activeClassName="activeNavBtn" className="NavLink" to="/signup">
        <button className="loginBtn" type="button">
          {t("Register")}
        </button>
      </NavLink> */}
    </Fragment>
  );
  const authLinks = () => (
    <Fragment>
      {!props.textColor && !isMobile && (
        <NavLink
          to="/shoppingcart"
          activeClassName="activeNavBtn"
          className="dustbin2"
        >
          <i
            className="fas fa-shopping-cart dustbinn"
            style={{ color: props.iconColor }}
          >
            <span style={{ fontWeight: "400" }}>
              {cart &&
                cart.reduce(
                  (accumulator, item) => accumulator + item.quantity,
                  0
                )}
            </span>
          </i>
        </NavLink>
      )}
      <NavLink
        activeClassName="activeNav"
        className="NavLink"
        isActive={isActive}
        to="/myaccount/acountdetail"
      >
        <span className="sr-only">(current)</span>
        <li className="nav-item-link">
          <span
            className="nav-link"
            style={{
              color: props.textColor ? props.textColor : "rgb(210, 210, 210)",
            }}
          >
            {t("My Account")}
          </span>
        </li>
      </NavLink>
    </Fragment>
  );

  const guestLinksMobile = () => (
    <Fragment style={{ display: "flex" }}>
      <NavLink activeClassName="activeNavBtn1" className="NavLink" to="/signin">
        <button
          className="loginBtnForMobile"
          type="button"
          style={{ color: props.iconColor, backgroundColor: props.bgColor }}
        >
          {t("Log in")}
        </button>
      </NavLink>
      <NavLink
        to="/shoppingcart"
        activeClassName="activeNavBtn"
        className="dustbin2"
      >
        <i
          className="fas fa-shopping-cart"
          style={{ color: props.iconColor }}
        ></i>
      </NavLink>

      {/* <NavLink activeClassName="activeNavBtn" className="NavLink" to="/signup">
        <button className="loginBtn" type="button">
          {t("Register")}
        </button>
      </NavLink> */}
    </Fragment>
  );
  const authLinksMobile = () => (
    <Fragment>
      <NavLink
        activeClassName="activeNav"
        className="dustbin3"
        isActive={isActive}
        to="/myaccount/acountdetail"
      >
        <span className="sr-only">(current)</span>
        <i className="fas fa-user" style={{ color: props.iconColor }}></i>
      </NavLink>
      <NavLink
        to="/shoppingcart"
        activeClassName="activeNavBtn"
        className="dustbin4"
      >
        <i
          className="fas fa-shopping-cart dustbinn"
          style={{ color: props.iconColor }}
        >
          <span style={{ fontWeight: "400" }}>
            {cart &&
              cart.reduce(
                (accumulator, item) => accumulator + item.quantity,
                0
              )}
          </span>
        </i>
      </NavLink>
    </Fragment>
  );
  const [ariaExpanded, setAriaExpanded] = useState("false");
  return (
    <section
      className="navBar"
      id="navbar_id"
      style={{ backgroundColor: props.bgColor }}
    >
      <header className="navbar navbar-expand-xl navbar-light">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setAriaExpanded(!ariaExpanded)}
        >
          {ariaExpanded && <span className="navbar-toggler-icon"></span>}
          {!ariaExpanded && (
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                class="bi bi-x"
                viewBox="0 0 15 15"
                style={{
                  stroke: "black",
                  strokeWidth: 1, // Set the stroke width
                }}
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
              </svg>
            </span>
          )}
        </button>
        {/* here is NavLink heading  added to className which i remove */}
        <NavLink to="/" className="d-flex figure">
          {isMobile && (
            <>
              <img className="img-fluid" src={Logo} alt="logo" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  lineHeight: "20px",
                }}
              >
                <span className="par" style={{ color: props.textColor }}>
                  Paradise
                </span>
                <span className="com" style={{ color: props.textColor }}>
                  Competitions
                </span>
              </div>
              {isAuthenticated ? authLinksMobile() : guestLinksMobile()}
            </>
          )}
          {!isMobile && (
            <>
              <img className="img-fluid mt-0" src={Logo} alt="logo" />
              <h1 className="figcaption" style={{ color: props.textColor }}>
                Paradise Competitions
              </h1>
            </>
          )}
        </NavLink>
        {/* {isAuthenticated ? authLinks() : guestLinks()} */}
        <nav
          style={{ backgroundColor: props.bgColor }}
          className="collapse navbar-collapse d-xl-flex justify-content-xl-end"
          id="navbarSupportedContent"
        >
          <ul
            className={
              isAuthenticated === true
                ? "loginSuccess navbar-nav p-0"
                : "navbar-nav p-0"
            }
          >
            <NavLink
              className="NavLink"
              activeClassName="activeNav"
              to="/competitions"
            >
              <span className="sr-only">(current)</span>
              <li className="nav-item active">
                <span
                  className="nav-link"
                  style={{
                    color: props.textColor
                      ? props.textColor
                      : "rgb(210, 210, 210)",
                  }}
                >
                  {t("competitions")}
                </span>
              </li>
            </NavLink>

            <NavLink
              className="NavLink"
              activeClassName="activeNav"
              to="/howtoplay"
            >
              <li className="nav-item">
                <span
                  className="nav-link"
                  style={{
                    color: props.textColor
                      ? props.textColor
                      : "rgb(210, 210, 210)",
                  }}
                >
                  {t("how to play")}
                </span>
              </li>
            </NavLink>

            <NavLink
              className="NavLink"
              activeClassName="activeNav"
              to="/winners#winnerPage"
            >
              <li className="nav-item">
                <span
                  className="nav-link"
                  style={{
                    color: props.textColor
                      ? props.textColor
                      : "rgb(210, 210, 210)",
                  }}
                >
                  {t("winners")}
                </span>
              </li>
            </NavLink>

            <NavLink
              className="NavLink"
              activeClassName="activeNav"
              to="/giftshop#giftPage"
              smooth
            >
              <li className="nav-item">
                <span
                  className="nav-link"
                  style={{
                    color: props.textColor
                      ? props.textColor
                      : "rgb(210, 210, 210)",
                  }}
                >
                  {t("gift shop")}
                </span>
              </li>
            </NavLink>

            <div style={{ position: "relative", color: "white" }}>
              <div className="england">
                <img
                  className="img-fluid"
                  src={`/images/${language}`}
                  id="flag"
                  alt=""
                />
                <select
                  style={{
                    fontWeight: 600,
                    fontSize: "1.7rem",
                    color: props.textColor,
                  }}
                  className="select-lang"
                  defaultValue={i18n.language}
                  onChange={changeLanguage}
                >
                  <option
                    value="ro"
                    style={{
                      fontWeight: 600,
                      fontSize: "1.7rem",
                      backgroundColor: props.bgColor,
                    }}
                  >
                    RO
                  </option>
                  <option
                    value="en"
                    style={{
                      fontWeight: 600,
                      fontSize: "1.7rem",
                      backgroundColor: props.bgColor,
                    }}
                  >
                    EN
                  </option>
                  {/* <option
                    value="bu"
                    style={{ fontWeight: 600, fontSize: "1.7rem" }}
                  >
                    BU
                  </option>
                  <option
                    value="de"
                    style={{ fontWeight: 600, fontSize: "1.7rem" }}
                  >
                    DE
                  </option>
                  <option
                    value="es"
                    style={{ fontWeight: 600, fontSize: "1.7rem" }}
                  >
                    ES
                  </option>
                  <option
                    value="fr"
                    style={{ fontWeight: 600, fontSize: "1.7rem" }}
                  >
                    FR
                  </option>
                  <option
                    value="it"
                    style={{ fontWeight: 600, fontSize: "1.7rem" }}
                  >
                    IT
                  </option>
                  <option
                    value="mo"
                    style={{ fontWeight: 600, fontSize: "1.7rem" }}
                  >
                    MO
                  </option>
                  <option
                    value="nl"
                    style={{ fontWeight: 600, fontSize: "1.7rem" }}
                  >
                    NL
                  </option>
                  <option
                    value="pt"
                    style={{ fontWeight: 600, fontSize: "1.7rem" }}
                  >
                    PT
                  </option> */}

                  {/* <option value='tr'>TR</option> */}
                </select>
              </div>
              {isAuthenticated ? authLinks() : guestLinks()}
            </div>
          </ul>
        </nav>
      </header>
    </section>
  );
};

export default Navbar;
