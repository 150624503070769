import React from "react";
import "./DisplayCompetitions.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { displayCompetitions } from "./DisplayCompetitions.js";

const DisplayCompetitions = () => {
  const { i18n, t } = useTranslation();
  const state = useSelector((state) => state);
  const { competitions } = state.competitions;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const titles = {
    1: t("Sat Session"),
    2: t("Active Competitions"),
    3: t("Fit 4 Summer Comps"),
    4: t("X-mas Comps"),
    5: t("Coupon Competition"),
    6: t("Black Friday"),
    7: t("Gaming is On!"),
    8: t("Charity Competition"),
    9: t("Kids Paradise"),
    10: t("DIY Comps"),
  };

  const display = (competitions) => {
    let itemList = [];
    Object.keys(competitions).forEach(function (key, index) {
      itemList.push(
        <section className="c-section-1">
          <h1 style={{ fontWeight: 200 }}>{titles[key]}</h1>
          <div className="items-container">
            {displayCompetitions(competitions[key], isMobile ? 36 : 45)}
          </div>
        </section>
      );
    });
    return itemList;
  };

  if (Object.keys(competitions).length !== 0) {
    return <>{display(competitions)}</>;
  }
  return <></>;
};

export default DisplayCompetitions;
