import React from "react";
import "./myaccount.css";
import OptionBar from "./optionBar/optionBar.jsx";
import Footer from "../pages/Landing-page/Footer/footer";
import Navbar from "../pages/Landing-page/Navbar/navbar.jsx";
import LinkComponents from "./linkComponents/linkComponent.jsx";

const Myaccount = (props) => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  return (
    <>
      <Navbar />
      <div className="myacount-con">
        <OptionBar />
        <LinkComponents>{props.children}</LinkComponents>
      </div>
      <Footer />
    </>
  );
};

export default Myaccount;
