import React, { useState, useEffect, Fragment } from "react";
// import "./navbar.css";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "../../../../assets/logo.png";
import { useTranslation } from "react-i18next";

function OrderNav(props) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const state = useSelector((state) => state);

  const cart = state.cart.cart;
  const isAuthenticated = state.user.isAuthenticated;
  const [language, setFlag] = useState("romania.png");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    switch (i18n.language) {
      case "ro":
        setFlag("romania.png");
        break;
      case "en":
        setFlag("england.png");
        break;
      default:
        setFlag("romania.png");
    }
  }, [i18n.language]);

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }

  const isActive = (match, location) => location.pathname.includes("myaccount");

  const guestLinks = () => (
    <Fragment>
      <NavLink
        to="/shoppingcart"
        className={({ isActive }) => (isActive ? "activeNavBtn" : "dustbin2")}
      >
        <i className="fas fa-shopping-cart" style={{ color: "black" }}></i>
      </NavLink>
      <NavLink
        className={({ isActive }) => (isActive ? "activeNavBtn" : "NavLink")}
        to="/signin"
      >
        <button className="loginBtn" type="button">
          {t("Client login")}
        </button>
      </NavLink>
    </Fragment>
  );

  const authLinks = () => (
    <Fragment>
      {!props.textColor && !isMobile && (
        <NavLink
          to="/shoppingcart"
          className={({ isActive }) => (isActive ? "activeNavBtn" : "dustbin2")}
        >
          <i
            className="fas fa-shopping-cart dustbinn"
            style={{ color: "black" }}
          >
            <span style={{ fontWeight: "400" }}>
              {cart &&
                cart.reduce(
                  (accumulator, item) => accumulator + item.quantity,
                  0
                )}
            </span>
          </i>
        </NavLink>
      )}
      <NavLink
        className={({ isActive }) => (isActive ? "activeNav" : "NavLink")}
        to="/myaccount/acountdetail"
      >
        <li className="nav-item-link">
          <span className="nav-link" style={{ color: "black" }}>
            {t("My Account")}
          </span>
        </li>
      </NavLink>
    </Fragment>
  );

  const guestLinksMobile = () => (
    <Fragment>
      <NavLink
        className={({ isActive }) => (isActive ? "activeNavBtn1" : "NavLink")}
        to="/signin"
      >
        <button
          className="loginBtnForMobile"
          type="button"
          style={{ color: "black", backgroundColor: "#e4e4e4" }}
        >
          {t("Log in")}
        </button>
      </NavLink>
      <NavLink
        to="/shoppingcart"
        className={({ isActive }) => (isActive ? "activeNavBtn" : "dustbin2")}
      >
        <i className="fas fa-shopping-cart" style={{ color: "black" }}></i>
      </NavLink>
    </Fragment>
  );

  const authLinksMobile = () => (
    <Fragment>
      <NavLink
        className={({ isActive }) => (isActive ? "activeNav" : "dustbin3")}
        to="/myaccount/acountdetail"
      >
        <i className="fas fa-user" style={{ color: "black" }}></i>
      </NavLink>

      <NavLink
        to="/shoppingcart"
        className={({ isActive }) => (isActive ? "activeNavBtn" : "dustbin4")}
      >
        <i className="fas fa-shopping-cart dustbinn" style={{ color: "black" }}>
          <span style={{ fontWeight: "400" }}>
            {cart &&
              cart.reduce(
                (accumulator, item) => accumulator + item.quantity,
                0
              )}
          </span>
        </i>
      </NavLink>
    </Fragment>
  );
  const [ariaExpanded, setAriaExpanded] = useState("false");
  return (
    <section
      className="navBar"
      id="navbar_id"
      style={{ backgroundColor: "#e4e4e4" }}
    >
      <header className="navbar navbar-expand-xl navbar-light">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setAriaExpanded(!ariaExpanded)}
        >
          {ariaExpanded && <span className="navbar-toggler-icon"></span>}
          {!ariaExpanded && (
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                class="bi bi-x"
                viewBox="0 0 15 15"
                style={{
                  stroke: "black",
                  strokeWidth: 1, // Set the stroke width
                }}
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
              </svg>
            </span>
          )}
        </button>
        <NavLink to="/" className="d-flex figure">
          {isMobile ? (
            <>
              <img className="img-fluid" src={Logo} alt="logo" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  lineHeight: "20px",
                }}
              >
                <span className="par" style={{ color: "black" }}>
                  Paradise
                </span>
                <span className="com" style={{ color: "black" }}>
                  Competitions
                </span>
              </div>
              {isAuthenticated ? authLinksMobile() : guestLinksMobile()}
            </>
          ) : (
            <>
              <img className="img-fluid mt-0" src={Logo} alt="logo" />
              <h1 className="figcaption" style={{ color: "black" }}>
                Paradise Competitions
              </h1>
            </>
          )}
        </NavLink>
        <nav
          style={{ backgroundColor: "#e4e4e4" }}
          className="collapse navbar-collapse d-xl-flex justify-content-xl-end"
          id="navbarSupportedContent"
        >
          <ul
            className={
              isAuthenticated ? "loginSuccess navbar-nav p-0" : "navbar-nav p-0"
            }
          >
            <NavLink
              className={({ isActive }) => (isActive ? "activeNav" : "NavLink")}
              to="/competitions"
            >
              <li className="nav-item active">
                <span className="nav-link" style={{ color: "black" }}>
                  {t("competitions")}
                </span>
              </li>
            </NavLink>

            <NavLink
              className={({ isActive }) => (isActive ? "activeNav" : "NavLink")}
              to="/howtoplay"
            >
              <li className="nav-item">
                <span className="nav-link" style={{ color: "black" }}>
                  {t("how to play")}
                </span>
              </li>
            </NavLink>

            <NavLink
              className={({ isActive }) => (isActive ? "activeNav" : "NavLink")}
              to="/winners"
              scroll={(el) =>
                window.scrollTo({
                  top: el.offsetTop - 30,
                  behavior: "smooth",
                })
              }
            >
              <li className="nav-item">
                <span className="nav-link" style={{ color: "black" }}>
                  {t("winners")}
                </span>
              </li>
            </NavLink>

            <NavLink
              className={({ isActive }) => (isActive ? "activeNav" : "NavLink")}
              to="/giftshop"
              scroll={(el) =>
                window.scrollTo({
                  top: el.offsetTop - 30,
                  behavior: "smooth",
                })
              }
            >
              <li className="nav-item">
                <span className="nav-link" style={{ color: "black" }}>
                  {t("gift shop")}
                </span>
              </li>
            </NavLink>

            <div style={{ position: "relative", color: "white" }}>
              <div className="england">
                <img
                  className="img-fluid"
                  src={`/images/${language}`}
                  id="flag"
                  alt=""
                />
                <select
                  style={{
                    fontWeight: 600,
                    fontSize: "1.7rem",
                    color: "black",
                  }}
                  className="select-lang"
                  defaultValue={i18n.language}
                  onChange={changeLanguage}
                >
                  <option
                    value="ro"
                    style={{
                      fontWeight: 600,
                      fontSize: "1.7rem",
                      backgroundColor: "#e4e4e4",
                    }}
                  >
                    RO
                  </option>
                  <option
                    value="en"
                    style={{
                      fontWeight: 600,
                      fontSize: "1.7rem",
                      backgroundColor: "#e4e4e4",
                    }}
                  >
                    EN
                  </option>
                </select>
              </div>
              {isAuthenticated ? authLinks() : guestLinks()}
            </div>
          </ul>
        </nav>
      </header>
    </section>
  );
}

export default OrderNav;
