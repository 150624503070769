import React from "react";
import axios from "axios";
import { REQUEST_URL } from "../../../constant/Constant";
import HeroSection from "./Hero-Section/hero-section.jsx";
import MainSection from "./MainSection/main-section.jsx";
import Footer from "./Footer/footer.jsx";
import Navbar from "./Navbar/navbar.jsx";
import moment from "moment";

import { withTranslation } from "react-i18next";

class LandingPage extends React.Component {
  state = {
    sold_tickets: [],
    index: 0,
    con: true,
  };

  load_tickets = async () => {
    try {
      const res = await axios.get(
        REQUEST_URL + `/api/competitions/sold/tickets/`
      );
      this.setState({ sold_tickets: res.data });
    } catch (err) {}
  };

  componentDidMount() {
    this.load_tickets(); //this function sending request to server to get latest sold tickets
    const showDiv = (element, vDelay, vDur) => {
      setInterval(() => {
        element.classList.add("active");
        setTimeout(() => {
          element.classList.remove("active");
          if (this.state.index < this.state.sold_tickets.length - 1) {
            this.setState({ index: this.state.index + 1 });
          } else {
            this.setState({ index: 0 });
          }
        }, vDur);
      }, vDelay);
    };
    const popUp = document.getElementsByClassName("popUpDiv")[0];
    const crossBtn = document.getElementsByClassName("fa-times-circle")[0];
    if (this.state.con) {
      if(this.state.sold_tickets.length>0){
        showDiv(popUp, 14000, 11000);
      }
    } else {
      console.log(
        this.state.sold_tickets.length > 0 &&
          REQUEST_URL + this.state.sold_tickets[this.state.index].image
      );
    }

    crossBtn.addEventListener("click", () => {
      popUp.classList.remove("active");
    });
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="popUpDiv row m-0 py-lg-4 py-3">
          <div className="col-3 p-0 m-0">
            <img
              src={
                this.state.sold_tickets.length > 0 &&
                `${this.state.sold_tickets[this.state.index].image}`
              }
              alt=""
            />
          </div>
          <div className="col">
            <i onClick={this.removeClass} className="far fa-times-circle"></i>
            <h1 className="mt-2">
              <span>
                {this.state.sold_tickets.length > 0 &&
                  this.state.sold_tickets[this.state.index].name}
              </span>{" "}
              purchased a<br />
              <span>
                Ticket:{" "}
                {this.state.sold_tickets.length > 0 &&
                  this.state.sold_tickets[this.state.index].ticket}
              </span>
            </h1>
            <p>
              {moment(
                this.state.sold_tickets.length > 0 &&
                  this.state.sold_tickets[this.state.index].sold_time
              ).fromNow()}
            </p>
          </div>
        </div>
        <Navbar />
        <HeroSection />
        <MainSection />
        <Footer />
      </>
    );
  }
}

export default withTranslation()(LandingPage);
