import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import { useTranslation } from "react-i18next";

export const InstantPrices = ({ competition }) => {
  const sectionRef = useRef(null);
  const seeAllRef = useRef(null);
  const [seeAll, setSeeAll] = useState(false);
  const { i18n, t } = useTranslation();

  const toggleSeeAll = () => {
    const adjustScroll = () => {
      const titlePosition = seeAllRef.current.getBoundingClientRect().top;
      const offset = window.pageYOffset + titlePosition - 100;
      window.scrollTo({ top: offset, behavior: "smooth" });
    };
  
    setSeeAll(!seeAll);
    setTimeout(adjustScroll, 0);
  };
  
  return (
    <section className="instantPrice" ref={sectionRef}>
      <span ref={seeAllRef} className="stickBar">
        <div onClick={toggleSeeAll}>
          <h1 className="priceSection">
            {seeAll?<span>-</span>:<span>+</span>}
            {t("Instant Win Prizes")}
          </h1>
        </div>
        <p className=" d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-0">
          {t("Hurry")}{" "}
          {competition?.insta_prizes?.reduce(
            (total, item) => total + item.quantity,
            0
          ) -
            competition?.insta_prizes?.reduce(
              (total, item) => total + item.give_away_count,
              0
            )}
          /
          {competition?.insta_prizes?.reduce(
            (total, item) => total + item.quantity,
            0
          )}{" "}
          {t("Hurry2")}
          {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}

        </p>
        <hr className="hrLine" />{" "}
      </span>

      <div
        className={`row w-100 my-4 ${seeAll ? "show" : ""}`}
      >
        <div style={{display:"flex", flexWrap:"wrap"}}>
          {competition?.insta_prizes?.map((prize) => {
            const { quantity, insta_prize } = prize;
            const prizeInstances = Array.from(
              { length: quantity },
              (_, index) => (
                <div key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                  <div className="item-wrapper">
                    <div 
                      className="items"
                      style={{
                        backgroundImage: `url(${prize.image})`,
                      }}
                    >
                      {index >= quantity - insta_prize.length ? (
                        <div
                          style={{ fontWeight: "600" }}
                          className="ticket-no"
                        >
                          Winner:{" "}
                          {
                            insta_prize[index - (quantity - insta_prize.length)]
                              .customer_name
                          }
                        </div>
                      ) : (
                        <div style={{ fontWeight: "600" }} className="not-won">
                          NOT WON
                        </div>
                      )}
                      {index >= quantity - insta_prize.length && (
                        <div
                          style={{ fontWeight: "600" }}
                          className="ticket-no"
                        >
                          Winning Ticket:{" "}
                          {
                            insta_prize[index - (quantity - insta_prize.length)]
                              .ticket
                          }
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            );
            // Render each prize instance
            return prizeInstances;
          })}
        </div>
        <div className="show-scroller"></div>
      </div>
    </section>
  );
};
