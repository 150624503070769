import React, { useState, useEffect } from "react";
import moment from "moment";
import "../../Ticket/tickets.css";
import Swiper from "swiper/bundle";
import Loader from "react-loader-spinner";
import { ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TIME_ZONE } from "../../../../../../constant/Constant";
import { doLuckyDip } from "../../../../../../actions/competitions/singleCompetitions";

export const Slider = ({ competition, activeKey }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const dispatch = useDispatch();
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const state = useSelector((state) => state);
  const isAuthenticated = state.user.isAuthenticated;
  const [luckyDipCount, setLuckyDipCount] = useState(0);
  const [luckyDip, setLuckyDip] = useState(false);
  const [sliderTime, setSliderTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  useEffect(() => {
    var galleryThumbs = new Swiper(".gallery-thumbs", {
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    });
    new Swiper(".gallery-top", {
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: galleryThumbs,
      },
    });
    const interval = setInterval(countdown, 1000);
    return () => clearInterval(interval);
  }, [competition.actual_closing_date]);

  const callBack = () => {
    setLuckyDip(false);
    setLuckyDipCount(0);
  };
  
  const countdown = () => {
    var countDownDate = new Date(
      moment(competition.actual_closing_date).locale("en").format("lll")
    ).getTime();
    var now = new Date().toLocaleString("en-US", {
      timeZone: TIME_ZONE,
    });
    var now = new Date(now).getTime();
    var distance = countDownDate - now;
    var Days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var Hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var Minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var Seconds = Math.floor((distance % (1000 * 60)) / 1000);
    if (distance < 0) {
      // let diffInMilliSeconds = Math.abs(countDownDate - now) / 1000;
      // const days = Math.floor(diffInMilliSeconds / 86400);
      // const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
      // const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
      setTimeout(() => {
        setSliderTime({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
      }, 1000);
    } else {
      setSliderTime({
        days: Days,
        hours: Hours,
        minutes: Minutes,
        seconds: Seconds,
      });
    }
  };

  const { days, hours, minutes, seconds } = sliderTime;
  function displayImages() {
    return competition.images.map((obj, i) => {
      return (
        <img
          key={i}
          src={obj.image}
          alt="competition"
          className="swiper-slide"
        />
      );
    });
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const updateSliderBackground = (value) => {
    const slider = document.querySelector(".lucky-dip-slider");
    const percentage = (value / competition.buying_limit) * 100;
    slider.style.background = `linear-gradient(to right, #00d7c5 ${percentage}%, #464646 ${percentage}%)`;
  };

  const handleDecrement = () => {
    setLuckyDipCount((prevCount) => {
      const newCount = Math.max(prevCount - 1, 0);
      updateSliderBackground(newCount);
      return newCount;
    });
  };

  const handleIncrement = () => {
    setLuckyDipCount((prevCount) => {
      const newCount = Math.min(prevCount + 1, competition.buying_limit);
      updateSliderBackground(newCount);
      return newCount;
    });
  };

  const handleSliderChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setLuckyDipCount(value);
    updateSliderBackground(value);
  };

  return (
    <section className="com-section-3" id="com-section-3 ">
      <h1 id="timer">{t("Prize")}</h1>
      <div className="portfolio-item-container">
        {/* image container */}
        {/* <div className="portfolio-item" id="timer"> */}
        <div className="portfolio-item">
          <div className="swiper gallery-top">
            <div className="swiper-wrapper">{displayImages()}</div>
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
          </div>
          {isMobile && (
            <div className="swiper gallery-thumbs mt-3 d-block d-lg-none">
              <div className="swiper-wrapper">{displayImages()}</div>
            </div>
          )}
          <h1>{competition.title}</h1>
          {competition.tickets_length.sold ===
          competition.tickets_length.total_tickets ? (
            <></>
          ) : competition.discount_active ? (
            <h2 style={{ marginTop: "10px" }}>
              <span
                className={"discount-bar"}
                style={{
                  color: "var(--primary)",
                  fontWeight: "400",
                  fontSize: "2rem",
                }}
              >
                {competition.first_day_price} Lei{" "}
              </span>

              <span
                style={{
                  color: "var(--primary)",
                  fontWeight: "400",
                  fontSize: "2rem",
                }}
              >
                {" "}
                {competition.discount_price} Lei
              </span>
            </h2>
          ) : (
            <h2>
              <span style={{ color: "var(--primary)", fontSize: "1.7rem" }}>
                Lei {competition.first_day_price}
              </span>
            </h2>
          )}
        </div>
        {/* secondsection */}
        <div className="portfolio-item">
          {!isMobile && (
            <div className="swiper gallery-thumbs d-none d-md-block">
              <div className="swiper-wrapper">{displayImages()}</div>
            </div>
          )}
          <div className="slider-counter">
            <p>{t("See Live Draw in")}</p>
            <div className="inner-counter">
              <div>
                <h1>{days}</h1>
                <h3>{t("Days")}</h3>
              </div>
              <div>
                <h1>{hours}</h1>
                <h3>{t("Hours")}</h3>
              </div>
              <div>
                <h1>{minutes}</h1>
                <h3>{t("Minutes")}</h3>
              </div>
              <div>
                <h1>{seconds}</h1>
                <h3>{t("Seconds")}</h3>
              </div>
            </div>
            <button className="comunity">
              <a
                href="https://www.facebook.com/paradisecompetitions.ro"
                style={{ color: "black" }}
              >
                <i className="fas fa-gift"></i>Go to Live Stream
              </a>
            </button>
            <h4>{moment(competition.actual_closing_date).format("llll")}</h4>
          </div>

          {/* 2 button */}
          <div className="btn-wrapper">
            <div className="lucky-dip-container">
              <p className="ticket-limit-text">
                Max {competition.buying_limit} per person
              </p>
              <div className="ticket-counter">
                <p className="counter-number" onClick={handleDecrement}>
                  -
                </p>
                <input
                  className="lucky-dip-slider"
                  value={luckyDipCount}
                  min={0}
                  max={competition.buying_limit}
                  onChange={handleSliderChange}
                  type="range"
                />
                <p className="counter-number" onClick={handleIncrement}>
                  +
                </p>
              </div>
              <button
                className="luckydrip"
                onClick={() => {
                  if (isAuthenticated) {
                    // setLuckyDipCountOne(luckyDipCountOne + 1);
                    dispatch(
                      doLuckyDip(
                        competition.id,
                        activeKey,
                        callBack,
                        luckyDipCount > 0 ? luckyDipCount : 1
                      )
                    );
                    setLuckyDip(true);
                  } else {
                    return history.replace("/signin");
                  }
                }}
                disabled={luckyDip}
              >
                {luckyDip && (
                  <Loader
                    style={{ display: "inline-block", color: "black" }}
                    type="ThreeDots"
                    color="white"
                    height={5}
                    width={30}
                  />
                )}
                {t("Lucky Dip")} <span>{luckyDipCount}</span>{" "}
              </button>
            </div>

            <NavLink className="tickets-add" to="/shoppingcart">
              <button className="tickets-addtocart">{t("Go to Cart")}</button>
            </NavLink>
          </div>
        </div>
      </div>

      <div className="valueConparent">
        <div className="valueCont">
          <div className="row m-0 p-0">
            <div className="col">{competition.tickets_length.sold}</div>
            <div className="col">
              {competition.tickets_length.available} {t("Left")}
            </div>
            <div className="col">{competition.total_tickets}</div>
          </div>
          <ProgressBar
            now={
              (competition &&
                competition.tickets_length.sold / competition.total_tickets) *
              100
            }
          />
        </div>
      </div>
    </section>
  );
};
