import axios from "axios";
import * as types from "../types";
import { getTokenIncludedConfig } from "../common";
import { REQUEST_URL } from "../../constant/Constant";
import { getCartData } from "../cart/cart";
import { ErrorToast, SuccessToast } from "../../components/toaster/toast";

export const loadCompetitionDetail = (competition_id) => async (dispatch) => {
  dispatch({
    type: types.COMPETITION_REQUEST_START,
  });
  try {
    const res = await axios.get(
      `${REQUEST_URL}/api/competitions/detail/${competition_id}/`
    );
    dispatch({
      type: types.GET_SINGLE_COMPETITION_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: types.GET_SINGLE_COMPETITION_FAIL,
    });
  }
};

export const loadTickets = (id, key) => async (dispatch) => {
  dispatch({
    type: types.TICKETS_REQUEST_START,
  });
  try {
    const res = await axios.get(
      `${REQUEST_URL}/api/competitions/tickets/?competition_id=${id}&key=${key}`
    );
    dispatch({
      type: types.GET_COMPETITION_TICKETS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: types.GET_COMPETITION_TICKETS_FAIL,
    });
  }
};

export const doLuckyDip = (id, activeKey, callBack, count=1) => async (dispatch) => {
  dispatch({
    type: types.LUCKY_DIP_START,
  });
  try {
    const res = await axios.get(
      `${REQUEST_URL}/api/competitions/luckydip/?competition_id=${id}&count=${count}`,
      getTokenIncludedConfig()
    );
    if (res.status === 207) {
      SuccessToast(res.data.message);
    }
    if (res.status === 200) {
      dispatch({
        type: types.LUCKY_DIP_ADD_TO_CART_SUCCESS,
        payload: res.data,
      });
      SuccessToast(
        `🥳 ${count} ticket${count > 1? "s": ""} have been successfully added to your cart. ✌`
      );
    }
    dispatch(loadTickets(id, activeKey));
    dispatch(getCartData())
    dispatch({
      type: types.LUCKY_DIP_SUCCESS,
    });
    callBack()
  } catch (err) {
    callBack()
    dispatch({
      type: types.LUCKY_DIP_FAIL,
    });
  }
};


export const getReviews = () => async (dispatch) => {
  dispatch({
    type: types.REVIEW_REQUEST_START,
  });
  try {
    const res = await axios.get(
      `${REQUEST_URL}/api/profile/reviews/`
    );
    dispatch({
      type: types.GET_REVIEWS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: types.GET_REVIEWS_FAIL,
    });
  }
};
