import "./gift-shop.css";
import ECard from "./childs/ecard";
import { Modal } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import React, { useEffect, useState } from "react";
import Footer from "../Landing-page/Footer/footer";
import { addToCart } from "../../../actions/cart/cart";
import Navbar from "../Landing-page/Navbar/navbar.jsx";
import { useSelector, useDispatch } from "react-redux";
import { loadGiftShop } from "../../../actions/giftshop/giftshop";

const GiftShop = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const state = useSelector((state) => state);
  const { loading, items } = state.giftshop;
  const isAuthenticated = state.user.isAuthenticated;
  const [modelShow, setModelShow] = useState(false);
  const [modelText, setModelText] = useState(false);

  useEffect(() => {
    dispatch(loadGiftShop());
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#giftPage") {
      const element = document.getElementById("giftPage");
      if (element) {
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - 120;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [location]);

  return (
    <>
      <Navbar />
      <section className="page-giftshopCon" id="giftPage">
        <ECard history={history} />
        {!loading ? (
          items &&
          items.map((obj, index) => {
            return (
              <>
                <div className="page-giftshop" key={index}>
                  <div>
                    <figure
                      style={{
                        backgroundImage: `url(${obj.image})`,
                      }}
                    ></figure>
                  </div>
                  <div>
                    <h2>{obj.title}</h2>
                    <p
                      onClick={() => {
                        setModelShow(true);
                        setModelText(obj.description);
                      }}
                      className="mt-2 expand-content "
                    >
                      {ReactHtmlParser(obj.description)}
                    </p>

                    <h1>Lei {obj.price} </h1>
                    <button
                      style={{
                        fontSize: "1.7rem",
                        cursor: "pointer",
                      }}
                      key={index}
                      value={obj.id}
                      onClick={(e) => {
                        if (isAuthenticated) {
                          dispatch(
                            addToCart(false, {
                              gift: e.target.value,
                            })
                          );
                        } else {
                          return history.replace("/signin");
                        }
                      }}
                    >
                      {t("Add to Cart")}
                    </button>
                    {/* <div className="page-soldout">Discounted via our Competitions</div> */}
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Loader type="ThreeDots" color="#00FFEA" height={80} width={80} />
          </div>
        )}
        <MyVerticallyCenteredModal
          text={modelText}
          show={modelShow}
          onHide={() => setModelShow(false)}
        />
      </section>

      <Footer />
    </>
  );
};

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="gift-shop-modal"
    >
      <Modal.Body>
        <div className="mt-2 ">{ReactHtmlParser(props.text)}</div>
      </Modal.Body>
    </Modal>
  );
}

export default GiftShop;
