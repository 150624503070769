import React, { useState, useEffect } from "react";
import "./activetickets.css";
import Loader from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import { getUserTickets } from "../../../../../actions/user/profileActions";

const ActiveTickets = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const isLoading = state.user.loading;
  const tickets = state.user.userTickets;
  useEffect(() => {
    dispatch(getUserTickets("active", currentPage));
  }, [currentPage]);
  console.log(tickets);
  const pageSize = 20;
  const totalCount = tickets?.count || 0;
  const totalPages = Math.ceil(totalCount / pageSize);

  const renderPageNumbers = () => {
    let pageButtons = [];

    pageButtons.push(
      <li key={1} className={`page-item ${currentPage === 1 ? "active" : ""}`}>
        <button className="page-link" onClick={() => setCurrentPage(1)}>
          1
        </button>
      </li>
    );

    if (currentPage > 4) {
      pageButtons.push(
        <li
          key="start-ellipsis"
          className="page-item disabled"
          style={{ color: "white", fontWeight: 700, fontSize: "2rem" }}
        >
          ...
        </li>
      );
    }
    const startPage = Math.max(2, currentPage - 1);
    const endPage = Math.min(totalPages - 1, currentPage + 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <li
          key={i}
          className={`page-item ${i === currentPage ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => setCurrentPage(i)}>
            {i}
          </button>
        </li>
      );
    }
    if (currentPage < totalPages - 2) {
      pageButtons.push(
        <li
          key="end-ellipsis"
          className="page-item disabled"
          style={{ color: "white", fontWeight: 700, fontSize: "2rem" }}
        >
          ...
        </li>
      );
    }

    if (totalPages > 1) {
      pageButtons.push(
        <li
          key={totalPages}
          className={`page-item ${currentPage === totalPages ? "active" : ""}`}
        >
          <button
            className="page-link"
            onClick={() => setCurrentPage(totalPages)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    return pageButtons;
  };

  return (
    <section className="activetickets" id="activetickets">
      {isLoading ? (
        <section className="activetickets">
          {" "}
          <div
            className="items-container"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Loader type="ThreeDots" color="#00FFEA" height={80} width={80} />
          </div>
        </section>
      ) : (
        tickets.results &&
        tickets.results.map((obj, i) => {
          return (
            <div key={obj.id} className="row activeTickets-detail m-0">
              <div className="col-2 col-lg-1 ticket-ids">
                <p>{obj.competition_id}</p>
              </div>
              <div
                className="col col-lg-7"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1rem",
                }}
              >
                <figure
                  style={{
                    backgroundImage: `url(${obj.competition_image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></figure>
                <div
                  className="heading"
                  style={{
                    gridColumn: "2 / 2",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                    hyphens: "auto",
                  }}
                >
                  <h3
                    style={{
                      color: "yellow",
                    }}
                  >
                    {obj.insta_prize && "LUCKY ME! WINNER!"}
                  </h3>

                  <h3>{obj.competition_label}</h3>
                </div>
              </div>
              <div className="col-3 col-lg-3">
                <button
                  style={{
                    backgroundColor: "var(--primary)",
                    padding: "7px",
                    width: "80px",
                    fontSize: "1.3rem",
                  }}
                >
                  {obj.ticket}
                </button>
              </div>
            </div>
          );
        })
      )}

      <div class="d-flex justify-content-start mt-4">
        <nav aria-label="Page navigation">
          <ul class="pagination">
            <li
              class={`page-item ${
                tickets?.previous === null ? "disabled" : ""
              }`}
            >
              <button
                disabled={tickets?.previous === null}
                onClick={() => setCurrentPage(currentPage - 1)}
                class="page-link"
                aria-label="Previous"
              >
                Prev
              </button>
            </li>
            {renderPageNumbers()}
            <li class={`page-item ${tickets?.next === null ? "disabled" : ""}`}>
              <button
                disabled={tickets?.next === null}
                onClick={() => {
                  console.log("Next button clicked");
                  setCurrentPage((prevPage) => prevPage + 1);
                }}
                class="page-link"
                aria-label="Next"
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
};
export default ActiveTickets;
