import React from "react";
import EcardVideo from "../../../../assets/eCardVideo.mp4";
import { useTranslation } from "react-i18next";

const ECard = ({ history , id}) => {
  const { i18n, t } = useTranslation();

  return (
    <>
      <h1 style={{fontSize:"3rem",display:"flex",justifyContent:"center"}}>{t("Gift Shop")}</h1>
      <div className="page-giftshop" id={id}>
        <div>
          <video className="video" controls muted>
            <source src={EcardVideo} type="video/mp4" />
          </video>
        </div>
        <div>
          <h2>{t("Surprize digital Fortune Cookie")}</h2>
          <p className="mt-2">
            {t(
              "We will choose one for you from more than 700 cookies with a surprize fortune cookie message."
            )}
          </p>
          <p className="mt-2">
            {t(
              "Download it on your mobile phone and share via WhatsApp with your friends and family."
            )}
          </p>
          <button
            style={{
              fontSize: "1.7rem",
              cursor: "pointer",
            }}
            onClick={(e) => {
              return history.replace("/competitions");
            }}
          >
            {t("Add to Cart")}
          </button>
          <div className="page-soldout">
            {t("Discounted via our Competitions")}
          </div>
        </div>
      </div>
    </>
  );
};

export default ECard;
