import "./cart.css";
import React, { useEffect } from "react";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import Total from "./childs/totalsection/Total";
import CartMiddleNav from "./childs/cartmiddlenav";
import CartItems from "./childs/caritems/cartitems";
import Navbar from "./childs/cartnavbar/cartnavbar";
import { useDispatch, useSelector } from "react-redux";
import { getDiscounts } from "../../actions/cart/cart";

const Cart = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const state = useSelector((state) => state);
  const { cart, discounts, loading } = state.cart;
  const user = state.user.user;
  useEffect(() => {
    dispatch(getDiscounts());
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="shoopingcart-body">
      <Navbar user={user} cart={cart} />
      {loading && cart.length === 0 && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader type="ThreeDots" color="#00FFEA" height={80} width={80} />
        </div>
      )}
      {cart.length !== 0 && (
        <>
          <CartMiddleNav />
          <section className="mainCart">
            {cart && <CartItems cart={cart} />}
            {cart && discounts && <Total cart={cart} discounts={discounts} />}
          </section>
        </>
      )}

      {cart.length === 0 && !loading && (
        <section
          className="main-section"
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "30px",
          }}
        >
          <h2>
            {t("No items in cart. Please add some.")}
          </h2>
        </section>
      )}
    </div>
  );
};

export default Cart;
