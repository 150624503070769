import React, { useEffect } from "react";
import "./posttickets.css";
import Loader from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import { REQUEST_URL } from "../../../../../constant/Constant";
import { getUserTickets } from "../../../../../actions/user/profileActions";

const PostTickets = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const isLoading = state.user.loading;
  const tickets = state.user.userTickets;
  useEffect(() => {
    dispatch(getUserTickets("past"));
  }, []);

  return (
    <section className="activetickets" id="activetickets">
      {isLoading ? (
        <section className="activetickets">
          {" "}
          <div
            className="items-container"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Loader type="ThreeDots" color="#00FFEA" height={80} width={80} />
          </div>
        </section>
      ) : (
        tickets.results &&
        tickets.results.map((obj, i) => {
          return (
            <div key={obj.id} className="row activeTickets-detail m-0">
              <div className="col-2 col-lg-1 ticket-ids">
                <p>{obj.id}</p>
              </div>
              <div
                className="col col-lg-7"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1rem",
                }}
              >
                <figure
                  style={{
                    backgroundImage: `url(${obj.competition_image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></figure>
                <div
                  className="heading"
                  style={{
                    gridColumn: "2 / 2",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                    hyphens: "auto",
                  }}
                >
                  <h3
                    style={{
                      color: "yellow",
                    }}
                  >
                    {obj.insta_prize && "LUCKY ME! WINNER!"}
                  </h3>

                  <h3>{!obj.insta_prize && obj.competition_label}</h3>
                </div>
              </div>
              <div className="col-3 col-lg-3">
                <button
                  style={{
                    backgroundColor: "var(--primary)",
                    padding: "7px",
                    width: "80px",
                    fontSize: "1.3rem",
                  }}
                >
                  {obj.ticket}
                </button>
              </div>
            </div>
          );
        })
      )}
    </section>
  );
};
export default PostTickets;
